import React, { Component } from "react";
import { observer, inject } from "mobx-react";
//import Stats from "../RegionSelector/RegionSelector";
import NewStats from "../NewRegionSelector/NewRegionSelector";
import { withTranslation } from "react-i18next";
import MySelect from "../basicUI/MySelect";
import MyCheckbox from "../basicUI/MyCheckbox";
import MyButton from "../basicUI/MyButton";
import Spinner from "react-bootstrap/Spinner";



@inject("commandStore", "userStore")
@observer
class Command extends Component {
    async componentDidMount() {
        try {
            let { t } = this.props;
            document.title = t("command.title");
            await this.props.commandStore.initiateStore();
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    async componentWillUnmount() {
        try {
            this.props.commandStore.cityHallData = null;
            this.props.commandStore.cityHallDataLast = null;
            this.props.commandStore.firstSaveGrouped = false
        } catch (error) {
            console.log(error.toString());
        }
    }

    overInProcess = async (e, row) => {
        //console.log(e);
        //console.log(e.movementX, e.movementY, e.clientX, e.clientY);
        let x = e.clientX;
        let y = e.clientY;
        //console.log(x, y);
        let style = {
            top: JSON.stringify(y) + "px",
            left: JSON.stringify(x - 120) + "px",
        };
        //console.log(style);
        let { t } = this.props;

        let textForModal = "";
        switch (row) {
            case "totalAds":
                textForModal = t("command.modalInfo.totalAds");
                break;
            case "withLicense":
                textForModal = t("command.modalInfo.withLicense");
                break;
            case "okAds":
                textForModal = t("command.modalInfo.okAds");
                break;
            case "withLicenseIncorrect":
                textForModal = t("command.modalInfo.withLicenseIncorrect");
                break;
            case "licenseDuplicated":
                textForModal = t("command.modalInfo.licenseDuplicated");
                break;
            case "usersWithLicense":
                textForModal = t("command.modalInfo.usersWithLicense");
                break;
            case "withAddressWithLicense":
                textForModal = t("command.modalInfo.withAddressWithLicense");
                break;
            case "withError":
                textForModal = t("command.modalInfo.withError");
                break;
            case "withErrorExempt":
                textForModal = t("command.modalInfo.withErrorExempt");
                break;
            case "withErrorNoLicense":
                textForModal = t("command.modalInfo.withErrorNoLicense");
                break;
            case "withErrors":
                textForModal = t("command.modalInfo.withErrors");
                break;
            case "idOwnerDuplicated":
                textForModal = t("command.modalInfo.idOwnerDuplicated");
                break;
            case "usersWithErrorLicense":
                textForModal = t("command.modalInfo.usersWithErrorLicense");
                break;
            case "WithErrorWithAddress":
                textForModal = t("command.modalInfo.withErrorWithAddress");
                break;
            case "fraud":
                textForModal = t("command.modalInfo.fraud");
                break;
            default:
                break;
        }

        document.getElementById("textModalCityHall").textContent = textForModal;
        document.getElementById("modalForCityHall").className = document
            .getElementById("modalForCityHall")
            .className.replace("display-none", "display-block");
        document.getElementById("modalForCityHall").style.left = style.left;
        document.getElementById("modalForCityHall").style.top = style.top;
    };

    closeModal = () => {
        //console.log("closing");
        document.getElementById("modalForCityHall").className = document
            .getElementById("modalForCityHall")
            .className.replace("display-block", "display-none");
        document.getElementById("modalForCityHall").style.top = "";
        document.getElementById("modalForCityHall").style.left = "";
    };

    onLeaves = async (e) => {
        /*let x = e.clientX;
let y = e.clientY;
let style = {
top: JSON.stringify(y) + "px",
left: JSON.stringify(x - 120) + "px",
};*/
        this.closeModal();
    };

    render() {
        const { t } = this.props;
        console.log(
            this.props.commandStore.cityHallData,
            this.props.commandStore.cityHallDataLast,
            this.props.commandStore.tableData,this.props.commandStore.initialized
        );
        let classname = this.props.commandStore.uniqueMonth ? "div-typeSelector-command" : "div-monthSelector-command";
        let month = new Date().getMonth()-1
        if (month === -1) month = 11
        return this.props.commandStore.cityHallData &&
            this.props.commandStore.cityHallDataLast &&
            this.props.commandStore.initialized ? (
            <>
                <div
                    id={"modalForCityHall"}
                    className={"modalHover commandHover display-none"}
                >
                    <section className="modal-main">
                        <div
                            id={"textModalCityHall"}
                            className="card container"
                            style={{ maxWidth: "200px" }}
                        >
                            {"A"}
                        </div>
                    </section>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <h4 className="stats-title">
                            {this.props.commandStore.cityName}
                        </h4>
                        <div style={{marginTop: "2rem"}}>
                            <p style={{marginBottom: "0px", marginLeft: "2.5%"}}>
                                {t("command.selectType")}
                                </p>
                            <div className="div-typeSelector-command">
                            <MySelect
                                selectName="typeSelector.fromOption"
                                selectClass="select"
                                selectedOption={
                                    this.props.commandStore.typeSelector
                                        .fromOption
                                }
                                onChange={
                                    this.props.commandStore.updateTypeSelector
                                }
                                selectOptions={
                                    this.props.commandStore.typeSelector
                                        .selectOptions
                                }
                            />
                            </div>
                        </div>
                        <div style={{marginTop: "1rem"}}>
                            <p style={{marginBottom: "0px", marginLeft: "2.5%"}}>

                            {this.props.commandStore.uniqueMonth ? 
                                t("command.selectMonth") : t("command.selectInterval")
                            }
                            </p>
                            <div className={classname}>
                                <MySelect
                                    selectName="startDate.fromOption"
                                    selectClass="select"
                                    selectedOption={
                                        this.props.commandStore.startDate
                                        .fromOption
                                    }
                                    onChange={
                                        this.props.commandStore
                                        .updateNestedProperty
                                    }
                                    selectOptions={
                                        this.props.commandStore.startDate
                                        .selectOptions
                                    }
                                    />
                                
                                {!this.props.commandStore.uniqueMonth ? (
                                <MySelect
                                    selectName="endDate.fromOption"
                                    selectClass="select"
                                    selectedOption={
                                        this.props.commandStore.endDate
                                            .fromOption
                                    }
                                    onChange={
                                        this.props.commandStore
                                            .updateNestedProperty
                                    }
                                    selectOptions={
                                        this.props.commandStore.endDate
                                            .selectOptions
                                    }
                                    />
                                ) : null}
                            </div>
                            <div className="div-saveMonths-command" style={{display:"flex"}}>
                                <p style={{marginBottom: "0px", marginLeft: "2.5%"}}>
                                    {t("command.groupStats")}
                                    </p>
                                <MyCheckbox
                                    key={"bothMonths-checkbox"}
                                    id={"bothMonths-checkbox"}
                                    name={"bothMonths-checkbox"}
                                    onChange={
                                        this.props.commandStore
                                        .changeMonthUniqueness
                                    }
                                    value={!this.props.commandStore.uniqueMonth}
                                />
                            
                                <MyButton
                                    id="button-filter-save-intervalDate"
                                    type="button"
                                    className="btn btn-info"
                                    text={t("command.updateDates")}
                                    onClick={() =>
                                    this.props.commandStore.saveMonths()
                                    }
                                />
                            </div>
                                
                        </div>
                        <div className="quick-search-statistics">
                            <NewStats page="command" regBool={true}></NewStats>
                        </div>
                        {this.props.commandStore.tableData ? 
                        <div className="row stats-table">
                        {this.props.commandStore.uniqueMonth ? 
                        <>
                        {!(this.props.commandStore.position === month+25 && month !== 11) && !(this.props.commandStore.position === 12 && month === 11) ?<MyButton
                            className="arrow-btn arrow-btn-left-command btn btn-default "
                            text={
                            <>
                                <svg
                                    className="icon-arrow-image-command"
                                    data-name="fas fa-angle-left"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 26.2 40.11"
                                >
                                    <path
                                        d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                                        transform="translate(-26.62 -21.2)"
                                    />
                                </svg>
                            </>
                        }
                        onClick={() => {this.props.commandStore.changePosition("left")}}
                        />:null}
                        
                        {this.props.commandStore.position !== month ? <MyButton
                            className="arrow-btn arrow-btn-right-command btn btn-default"
                            text={
                            <>
                                <svg
                                    className="icon-arrow-image-command"
                                    data-name="fas fa-angle-right"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 26.12 40.11"
                                >
                                    <path
                                        d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                                        transform="translate(-26.54 -20.81)"
                                    />
                                </svg>
                            </>
                            }
                        onClick={() => {this.props.commandStore.changePosition("right")}}
                        />:null}
                        </>
                        :null}
                            <p style={{ "textAlign": "left" ,marginLeft: "2%" }}>
                                {t("statistics.stats.date") +
                                    this.props.commandStore.dateInfo}
                            </p>
                            <p style={{ "textAlign": "left" ,marginLeft: "2%" }}>{t("command.ref")}</p>
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="cover"></th>

                                        <th>{t("command.total")}</th>
                                        {
                                            this.props.commandStore.tableData.websites ? 
                                            Object.keys(
                                                this.props.commandStore.tableData
                                                .websites
                                            ).map((currentValue, i) => (
                                                <th key={"" + currentValue + i}>
                                                {currentValue}
                                                </th>
                                            ))
                                            :null
                                        }
                                            </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.totalAds")}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "total_ads",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.total_ads
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? 
                                        Object.values(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "total_ads",
                                                        Object.keys(
                                                            this.props
                                                                .commandStore
                                                                .tableData
                                                                .websites
                                                        )[i]
                                                    );
                                                }}
                                            >
                                                {currentValue}
                                            </td>
                                        )):null}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>{t("municipality.correct")}</th>

                                        <th>{t("municipality.total")}</th>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        )):null}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="command-good-tr">
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withLicense"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withLicense.name")}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "license",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withLicense.count
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "license",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-goodsub-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(e, "okAds");
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withLicense.official")}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "okAds",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withLicense.official.count
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.official
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "okAds",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.official
                                                        .websites[currentValue]
                                                }
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-goodsub-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withLicenseIncorrect"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withLicense.incorrect")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget === e.target
                                                )
                                                    this.props.commandStore.tableToWA(
                                                        "licenseWithErrors",
                                                        "total"
                                                    );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withLicense.incorrect.count
                                            }

                                            {this.props.commandStore.uniqueMonth ? <div className="div-content">
                                                <button
                                                    id="flt-message"
                                                    className="btn btn-outline-info flt-message"
                                                    onClick={() => {
                                                        console.log(
                                                            "Downloading"
                                                        );
                                                        this.props.commandStore.downloadExcel(
                                                            this.props.userStore
                                                                .user.username,
                                                            "all",
                                                            "licenseWithErrors"
                                                        );
                                                    }}
                                                    type="button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                        style={{
                                                            fill: "#17a2b8",
                                                            minWidth: "1rem",
                                                        }}
                                                    >
                                                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                    </svg>
                                                </button>
                                            </div>:null}
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.incorrect
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        e.currentTarget ===
                                                        e.target
                                                    )
                                                        this.props.commandStore.tableToWA(
                                                            "licenseWithErrors",
                                                            currentValue
                                                        );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.incorrect
                                                        .websites[currentValue]
                                                }
                                                {this.props.commandStore.uniqueMonth ? <div className="div-content">
                                                    <button
                                                        id="flt-message"
                                                        className="btn btn-outline-info flt-message"
                                                        onClick={() => {
                                                            console.log(
                                                                "Downloading"
                                                            );
                                                            this.props.commandStore.downloadExcel(
                                                                this.props
                                                                    .userStore
                                                                    .user
                                                                    .username,
                                                                currentValue,
                                                                "licenseWithErrors"
                                                            );
                                                        }}
                                                        type="button"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            style={{
                                                                fill: "#17a2b8",
                                                                minWidth:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                        </svg>
                                                    </button>
                                                </div>:null}
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-good-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withAddressWithLicense"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withLicense.withAddress"
                                            )}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "licenseOnPortal",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withLicense.withAddress
                                                    .count
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.withAddress
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "licenseOnPortal",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withLicense.withAddress
                                                        .websites[currentValue]
                                                }
                                            </td>
                                        )):null}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>{t("municipality.doubt")}</th>

                                        <th>{t("municipality.total")}</th>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        )):null}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="command-doubt-tr">
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withError"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withErrorLicense.name")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget === e.target
                                                )
                                                    this.props.commandStore.tableToWA(
                                                        "errorLicense",
                                                        "total"
                                                    );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withErrorLicense.count
                                            }

{this.props.commandStore.uniqueMonth ? <div className="div-content">
                                                <button
                                                    id="flt-message"
                                                    className="btn btn-outline-info flt-message"
                                                    onClick={() => {
                                                        console.log(
                                                            "Downloading"
                                                        );
                                                        this.props.commandStore.downloadExcel(
                                                            this.props.userStore
                                                                .user.username,
                                                            "all",
                                                            "errorLicense"
                                                        );
                                                    }}
                                                    type="button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                        style={{
                                                            fill: "#17a2b8",
                                                            minWidth: "1rem",
                                                        }}
                                                    >
                                                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                    </svg>
                                                </button>
                                            </div>:null}
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        e.currentTarget ===
                                                        e.target
                                                    )
                                                        this.props.commandStore.tableToWA(
                                                            "errorLicense",
                                                            currentValue
                                                        );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .websites[currentValue]
                                                }
                                                {this.props.commandStore.uniqueMonth ? <div className="div-content">
                                                    <button
                                                        id="flt-message"
                                                        className="btn btn-outline-info flt-message"
                                                        onClick={() => {
                                                            console.log(
                                                                "Downloading"
                                                            );
                                                            this.props.commandStore.downloadExcel(
                                                                this.props
                                                                    .userStore
                                                                    .user
                                                                    .username,
                                                                currentValue,
                                                                "errorLicense"
                                                            );
                                                        }}
                                                        type="button"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            style={{
                                                                fill: "#17a2b8",
                                                                minWidth:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                        </svg>
                                                    </button>
                                                </div>:null}
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-doubtsub-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withErrorExempt"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.exempt"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "exempt",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withErrorLicense.exempt
                                                    .count
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense.exempt
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "exempt",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense.exempt
                                                        .websites[currentValue]
                                                }
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-doubtsub-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withErrorNoLicense"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.noLicense"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "noLicense",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withErrorLicense.noLicense
                                                    .count
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .noLicense.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "noLicense",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .noLicense.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-doubtsub-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withErrors"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.withErrors"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "errorOnLicenseCode",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withErrorLicense.withErrors
                                                    .count
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .withErrors.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "errorOnLicenseCode",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .withErrors.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-doubt-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "licenseDuplicated"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withLicense.licenseDuplicated"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "licenseDuplicated",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withLicense.duplicated
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.values(
                                            this.props.commandStore.tableData
                                                .licenses.withLicense.websites
                                        ).map((currentValue, i) => (
                                            <td key={"a" + currentValue + i}>
                                                {"-"}
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-doubt-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "idOwnerDuplicated"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.idDuplicated"
                                            )}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "idOwnerDuplicated",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withErrorLicense.duplicated
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.values(
                                            this.props.commandStore.tableData
                                                .licenses.withErrorLicense
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td key={"c" + currentValue + i}>
                                                {"-"}
                                            </td>
                                        )):null}
                                    </tr>
                                    <tr className="command-doubt-tr">
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "WithErrorWithAddress"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.withAddress"
                                            )}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "errorOnPortal",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .tableData.licenses
                                                    .withErrorLicense
                                                    .withAddress.count
                                            }
                                        </td>
                                        {this.props.commandStore.tableData.websites ? Object.keys(
                                            this.props.commandStore.tableData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .withAddress.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "errorOnPortal",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .withAddress.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        )):null}
                                    </tr>
                                </tbody>
                            </table>

                            {this.props.commandStore.errorGroupAdsCount ? (
                                <p>
                                    {this.props.commandStore.tableData.licenses
                                        .withErrorLicense.count +
                                        t("command.ads") +
                                        this.props.commandStore
                                            .errorGroupAdsCount +
                                        t("command.reducted") +
                                        Math.round(
                                            ((1 -
                                                this.props.commandStore
                                                    .errorGroupAdsCount /
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .count) *
                                                /// this.props.commandStore.errorGroupAdsCount

                                                100 +
                                                Number.EPSILON) *
                                                100
                                        ) /
                                            100 +
                                        t("command.percentage")}
                                </p>
                            ) : null}

                            {this.props.commandStore.tableData.licenses
                                .withErrorLicense.realFraud ? (
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>{t("municipality.fraud")}</th>

                                            <th>{t("municipality.total")}</th>
                                            {this.props.commandStore.tableData.websites ? Object.keys(
                                                this.props.commandStore
                                                    .tableData.websites
                                            ).map((currentValue, i) => (
                                                <th key={"" + currentValue + i}>
                                                    {currentValue}
                                                </th>
                                            )):null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="command-bad-tr">
                                            <td
                                                viewBox="0 0 512 512"
                                                onMouseEnter={(e) => {
                                                    this.overInProcess(
                                                        e,
                                                        "fraud"
                                                    );
                                                }}
                                                onMouseLeave={(e) => {
                                                    this.onLeaves(e);
                                                }}
                                            >
                                                {t(
                                                    "command.withErrorLicense.fraud"
                                                )}
                                            </td>

                                            <td
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget === e.target
                                                )
                                                    this.props.commandStore.tableToWA(
                                                        "realFraud",
                                                        "total"
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .tableData.licenses
                                                        .withErrorLicense
                                                        .realFraud.count
                                                }
                                                {this.props.commandStore.uniqueMonth ? <div className="div-content">
                                                    <button
                                                        id="flt-message"
                                                        className="btn btn-outline-info flt-message"
                                                        onClick={() => {
                                                            console.log(
                                                                "Downloading"
                                                            );
                                                            this.props.commandStore.downloadExcel(
                                                                this.props
                                                                    .userStore
                                                                    .user
                                                                    .username,
                                                                "all",
                                                                "realFraud"
                                                            );
                                                        }}
                                                        type="button"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            style={{
                                                                fill: "#17a2b8",
                                                                minWidth:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                        </svg>
                                                    </button>
                                                </div>:null}
                                            </td>

                                            {this.props.commandStore.tableData.websites ? Object.keys(
                                                this.props.commandStore
                                                    .tableData.websites
                                            ).map((currentValue, i) => (
                                                <td
                                                    key={
                                                        "" +
                                                        this.props.commandStore
                                                            .tableData.licenses
                                                            .withErrorLicense
                                                            .realFraud.websites[
                                                            currentValue
                                                        ] +
                                                        i
                                                    }
                                                    onClick={(e) => {
                                                        
                                                            e.stopPropagation();
                                                        if (
                                                            e.currentTarget === e.target
                                                        )
                                                            this.props.commandStore.tableToWA(
                                                                "realFraud",
                                                                "total"
                                                            );
                                                        
                                                    }}
                                                >
                                                    {
                                                        this.props.commandStore
                                                            .tableData.licenses
                                                            .withErrorLicense
                                                            .realFraud.websites[
                                                            currentValue
                                                        ]
                                                    }
                                                    {this.props.commandStore.uniqueMonth ? <div className="div-content">
                                                    <button
                                                        id="flt-message"
                                                        className="btn btn-outline-info flt-message"
                                                        onClick={() => {
                                                            console.log(
                                                                "Downloading"
                                                            );
                                                            this.props.commandStore.downloadExcel(
                                                                this.props
                                                                    .userStore
                                                                    .user
                                                                    .username,
                                                                currentValue,
                                                                "realFraud"
                                                            );
                                                        }}
                                                        type="button"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            style={{
                                                                fill: "#17a2b8",
                                                                minWidth:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                        </svg>
                                                    </button>
                                                </div>:null}
                                                </td>
                                            )):null}
                                        </tr>
                                    </tbody>
                                </table>
                            ) : null}
                        </div>:<>
                        {this.props.commandStore.uniqueMonth ? 
                        <>
                        {!(this.props.commandStore.position === month+25 && month !== 11) && !(this.props.commandStore.position === 12 && month === 11) ?<MyButton
                            className="arrow-btn arrow-btn-left-command btn btn-default "
                            text={
                            <>
                                <svg
                                    className="icon-arrow-image-command"
                                    data-name="fas fa-angle-left"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 26.2 40.11"
                                >
                                    <path
                                        d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                                        transform="translate(-26.62 -21.2)"
                                    />
                                </svg>
                            </>
                        }
                        onClick={() => {this.props.commandStore.changePosition("left")}}
                        />:null}
                        
                        {this.props.commandStore.position !== month ? <MyButton
                            className="arrow-btn arrow-btn-right-command btn btn-default"
                            text={
                            <>
                                <svg
                                    className="icon-arrow-image-command"
                                    data-name="fas fa-angle-right"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 26.12 40.11"
                                >
                                    <path
                                        d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                                        transform="translate(-26.54 -20.81)"
                                    />
                                </svg>
                            </>
                            }
                        onClick={() => {this.props.commandStore.changePosition("right")}}
                        />:null}
                        </>
                        :null}</>}

                        {/* { this.props.commandStore.cityHallData.licenses.withErrorLicense.count} */}
                        {/*Total anuncis dubtosos (llincencia incorrecte) municipi i numero de grups*/}
                    </div>
                <div className="col-md-3"></div>
                </div>
            </>
        ) : (
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="row stats-table">
                        <h4 className="stats-title">
                            {this.props.commandStore.cityName}
                        </h4>
                        <div className="quick-search-statistics">
                            <NewStats page="command" regBool={true}></NewStats>
                        </div>
                        <div className="SpinnerDiv">
                      <Spinner
                        animation="border"
                        variant="info"
                        role="status"
                        className="loadingSpinner"
                      >
                        <span className="sr-only">{t("loading")}</span>
                      </Spinner>
                      <p>{t("loading")}</p>
                    </div>
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
        );
    }
}

export default withTranslation()(Command);
