import { observable, action, computed } from "mobx";
import i18n from "../../i18n";
// import { useTranslation, Trans } from 'react-i18next';
// const { t, i18n } = useTranslation();

// const changeLanguage = lng => {
// 	i18n.changeLanguage(lng);
// };

class UserProfileStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateFormProperty = this.updateFormProperty.bind(this);
    this.updateCredentialsFormProperty = this.updateCredentialsFormProperty.bind(
      this
    );
    this.lowerCaseLetters = /[a-z]/g;
    this.upperCaseLetters = /[A-Z]/g;
    this.numbers = /[0-9]/g;
    this.specialChars = /[^\w\s]/gi;
    this.requiredLength = 8;
  }

  @observable editing = false;
  @observable modalEditProfileShow = false;

  @action("Change state of modalEditProfileShow")
  toggleModalEditProfile = () => {
    this.modalEditProfileShow = !this.modalEditProfileShow;
  };

  @observable modalEditPasswordShow = false;

  @action("Change state of modalEditProfileShow")
  toggleModalEditPassword = () => {
    this.modalEditPasswordShow = !this.modalEditPasswordShow;
  };

  @observable modalEditCredentialsShow = false;

  @action("Change state of modalEditProfileShow")
  toggleModalEditCredentials = () => {
    this.modalEditCredentialsShow = !this.modalEditCredentialsShow;
  };

  @observable userProfileForm = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    id: "",
    bio: "",
    areas: [],
    ads: [],
    gender: "",
    daltonism: "",
  };

  @observable userProfile = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    id: "",
    bio: "",
    areas: [],
    ads: [],
    gender: "",
    daltonism: "",
  };

  // Control variables
  @observable formSent = false;
  @observable formCouldntBeSent = false;
  @observable formIsValid = true;
  @observable emailConfirmed = false;
  @observable isLoading = false;

  // @observable showError = false
  @observable passwordFormIsValid = true;
  @observable showAlert = false;
  @observable confirmAlert = false;

  @observable passwordObject = {
    passwordRepeat: "",
    password: "",
  };

  @observable response = null;

  @observable credentialsForm = {
    webName: "airbnb",
    webUsername: "",
    webPassword: "",
    webFirstName: "",
    webLastName: "",
    webEmail: "",
  };

  selectOptions = [
    { key: "airbnb", value: "Airbnb" },
    { key: "booking", value: "Booking" },
    { key: "homeaway", value: "Homeaway" },
    { key: "housetrip", value: "Housetrip" },
    { key: "leboncoin", value: "Leboncoin" },
    { key: "niumba", value: "Niumba" },
    { key: "rentalia", value: "Rentalia" },
    { key: "tripadvisor", value: "Tripadvisor" },
  ];

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("Get user by id") getUserById = async (id) => {
    try {
      let res = await this.rootStore.requestStore.getUserById(id);
      this.userProfileForm.firstName = this.userProfile.firstName =
        res.data.firstName;
      this.userProfileForm.lastName = this.userProfile.lastName =
        res.data.lastName;
      this.userProfileForm.username = this.userProfile.username =
        res.data.username;
      this.userProfileForm.email = this.userProfile.email = res.data.email;
      this.userProfileForm.id = this.userProfile.id = res.data._id;
      this.userProfileForm.group = this.userProfile.group = res.data.group;
      this.userProfileForm.gender = this.userProfile.gender = res.data.gender;
      this.userProfileForm.daltonism = this.userProfile.daltonism = res.data.daltonism;
      this.userProfile.areas = [];

      let aux = res.data.assignedRegions;
      this.response = await this.rootStore.requestStore.getAdsTeam(
        this.userProfileForm.username,
        aux
      );

      // await aux.forEach(async(area)=>{
      // 	let res = await this.rootStore.requestStore.getRegion(area)
      // 	let total = 0
      // 	for (let key in res.data.numAdsByWebsite){
      // 		if(res.data.numAdsByWebsite.hasOwnProperty(key)){
      // 			total += res.data.numAdsByWebsite[key]
      // 		}
      // 	}
      // 	this.userProfile.ads.push(total)
      // 	let res2 = await this.rootStore.requestStore.getApartmentsData(res.data)

      // 	res.data["workingAds"] = 0;
      // 	for(let ad of res2.data){
      // 		if(ad.workingUser === this.userProfile.username) {
      // 			res.data["workingAds"] += 1;
      // 		}
      // 	}
      // 	this.userProfile.areas.push(res.data)
      // })
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get total ads") getTotalAds = (i) => {
    let res = this.userProfile.ads[i];
    return res;
  };

  @action("save current changes") saveChanges = async () => {
    try {
      let formCopy = JSON.parse(JSON.stringify(this.userProfileForm));

      let diff = {
        username: "noModified",
        email:"noModified",
        firstName: "noModified",
        lastName: "noModified",
        group: "noModified",
        gender: "noModified",
        daltonism: "noModified",
      }

      if(this.userProfile.username !== formCopy.username) diff.username = this.userProfile.username;
      if(this.userProfile.email !== formCopy.email) diff.email = this.userProfile.email;
      if(this.userProfile.firstName !== formCopy.firstName) diff.firstName = this.userProfile.firstName;
      if(this.userProfile.lastName !== formCopy.lastName) diff.lastName = this.userProfile.lastName;
      if(this.userProfile.group !== formCopy.group) diff.group = this.userProfile.group;
      if(this.userProfile.gender !== formCopy.gender) diff.gender = this.userProfile.gender;
      if(this.userProfile.daltonism !== formCopy.daltonism) diff.daltonism = this.userProfile.daltonism;

      this.userProfile = formCopy;
      let res = await this.rootStore.requestStore.sendUpdateUser(
        this.userProfile.id,
        this.userProfile,
        "Profile",
        diff,
      );
      console.log(res);
      console.log(this.props.userStore.user);
      if (res === undefined) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-profile").innerHTML = i18n.t(
          "ga.notifications.notUpdated"
        );
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      } else {
        this.updateProperty("confirmAlert", true);
        document.getElementById("succes-alert-profile").innerHTML = i18n.t(
          "ga.notifications.updated"
        );
        setTimeout(() => {
          this.updateProperty("confirmAlert", false);
        }, 5000);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("discard current changes") discardChanges = () => {
    let formCopy = JSON.parse(JSON.stringify(this.userProfile));
    this.userProfileForm = formCopy;
  };

  @action("update password values") updatePasswordForm = (key, value) => {
    this.passwordObject[key] = value;
  };

  @action("Restart password values") restartPassword = () => {
    this.passwordObject.password = "";
    this.passwordObject.passwordRepeat = "";
  };

  @action("update form property") updateFormProperty(key, value) {
    this.userProfileForm[key] = value;
  }

  @action("update form property") updateCredentialsFormProperty(key, value) {
    // console.log(key)
    // console.log(value)
    this.credentialsForm[key] = value;
  }

  @action registerWebCredentials = async (e) => {
    console.log("NEW WEB CREDENTIALS =", JSON.stringify(this.credentialsForm));
    //console.log("ENTERING IN REGISTER WEB CREDENTIALS")
    
    if (
      (["airbnb", "tripadvisor", "leboncoin"].includes(
        this.credentialsForm.webName
      ) &&
      this.credentialsForm.webUsername !== "" &&
      this.credentialsForm.webPassword !== "") ||
      (["housetrip", "niumba", "rentalia"].includes(
        this.credentialsForm.webName
      ) &&
      this.credentialsForm.webEmail !== "" &&
      this.credentialsForm.webFirstName !== "" &&
      this.credentialsForm.webLastName !== "")
    ) {
      let user = this.rootStore.userStore.getUser();
      let res = await this.rootStore.requestStore.registerWebCredentials(
        user.username,
        {
          webUsername: this.credentialsForm.webUsername,
          webPassword: this.credentialsForm.webPassword,
          webName: this.credentialsForm.webName,
          webFirstName: this.credentialsForm.webFirstName,
          webLastName: this.credentialsForm.webLastName,
          webEmail: this.credentialsForm.webEmail,
        }
      );
      if (res === "err") this.authError = true;
    }
    //console.log("EXIT REGISTER WEB CREDENTIALS")
  };

  @computed get hasLetter() {
    return !!this.passwordObject.password.match(this.lowerCaseLetters);
  }
  @computed get hasCapital() {
    return !!this.passwordObject.password.match(this.upperCaseLetters);
  }
  @computed get hasNumber() {
    return !!this.passwordObject.password.match(this.numbers);
  }
  @computed get hasSpecialChar() {
    return (
      !!this.passwordObject.password.match(this.specialChars) ||
      this.passwordObject.password.match("_")
    );
  }
  @computed get hasLength() {
    return this.passwordObject.password.length >= this.requiredLength;
  }
  @computed get passwordsMatch() {
    return (
      this.passwordObject.password.length > 0 &&
      this.passwordObject.password === this.passwordObject.passwordRepeat
    );
  }

  @computed get getPasswordFormIsValid() {
    return (
      this.hasCapital &&
      this.hasLength &&
      this.hasLetter &&
      this.hasNumber &&
      this.hasSpecialChar &&
      this.passwordsMatch
    );
  }

  @action("update the className") updateInvalidClassName(message) {
    let element = document.getElementById(message);
    if (element.className === "controlDisable form-control generic-input ") {
      element.className = element.className.replace(
        "controlDisable form-control generic-input ",
        "controlDisable form-control generic-input is-invalid"
      );
    } else if (
      element.className === "controlDisable form-control generic-input is-valid"
    ) {
      element.className = element.className.replace(
        "controlDisable form-control generic-input is-valid",
        "controlDisable form-control generic-input is-invalid"
      );
    }
  }

  @action("update the className") resetClassName(message) {
    let element = document.getElementById(message);
    if (
      element !== null &&
      element.className === "controlDisable form-control generic-input is-valid"
    )
      element.className = element.className.replace(
        "controlDisable form-control generic-input is-valid",
        "controlDisable form-control generic-input "
      );
    else if (
      element !== null &&
      element.className ===
        "controlDisable form-control generic-input is-invalid"
    )
      element.className = element.className.replace(
        "controlDisable form-control generic-input is-invalid",
        "controlDisable form-control generic-input "
      );
  }

  updatePassword = async () => {
    try {
      this.updateProperty("formSent", true);
      this.updateProperty("passwordFormIsValid", this.getPasswordFormIsValid);
      this.updateProperty("showError", false);
      if (this.passwordFormIsValid) {
        const response = await this.rootStore.requestStore.updatePasswordViaProfile(
          this.userProfileForm.username,
          this.passwordObject.password
        );
        if (response !== undefined) {
          this.updateProperty("confirmAlert", true);
          document.getElementById("succes-alert-profile").innerHTML =
            "La contrasenya s'ha actualitzat correctament.";
          setTimeout(() => {
            this.updateProperty("confirmAlert", false);
          }, 5000);

          this.updateProperty("passwordHasBeenUpdated", true);
          // this.updateProperty('showError', false)
          this.updateProperty("formSent", false);
          this.restartPassword();
          //this.updateFormProperty("password", "");
          //this.updateFormProperty("confirmPassword", "");
        } else {
          this.updateProperty("showAlert", true);
          document.getElementById("danger-alert-profile").innerHTML =
            "La contrasenya no s'ha pogut actualitzar. Siusplau, intenti-ho més tard.";
          setTimeout(() => {
            this.updateProperty("showAlert", false);
          }, 5000);

          this.updateProperty("passwordHasBeenUpdated", false);
          // this.updateProperty('showError', true)
          this.updateProperty("formSent", false);
          this.restartPassword();
        }
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
      // this.updateProperty('showError', true)
      this.updateProperty("formSent", false);
      this.updateProperty("isLoading", false);
    }
  };

  @computed get hasFirstname() {
    return this.userProfileForm.firstName !== "";
  }

  @computed get hasLastname() {
    return this.userProfileForm.lastName !== "";
  }

  @computed get hasUsername() {
    return this.userProfileForm.username !== "";
  }

  @computed get hasGroup() {
    return this.userProfileForm.group !== "";
  }

  @computed get hasEmail() {
    return this.userProfileForm.email !== "";
  }

  @computed get checkIfFormIsValid() {
    return (
      this.hasFirstname &&
      this.hasLastname &&
      this.hasUsername &&
      this.hasGroup &&
      this.hasEmail
    );
  }

  @action("update user profile") sendUpdateUser = async () => {
    try {
      this.updateProperty("formSent", true);
      // this.updateProperty('showError', false)
      this.updateProperty("formIsValid", this.checkIfFormIsValid);

      if (this.formIsValid) {
        this.updateProperty("isLoading", true);

        let diff = {
          username:"noModified",
          email:"noModified",
          team:"noModified",
        }
        if(this.userProfileForm.username !== this.userProfile.username) diff.username = this.userProfile.username;
        if(this.userProfileForm.email !== this.userProfile.email) diff.email = this.userProfile.email;
        if(this.userProfileForm.group !== this.userProfile.group) diff.group = this.userProfile.group;

        const response = await this.rootStore.requestStore.sendUpdateUser(
          this.userProfileForm.id,
          {
            username: this.userProfileForm.username,
            email: this.userProfileForm.email,
            team: this.userProfileForm.group,
          },
          "Profile",diff
        );

        if (response.data === "user not found") {
          // this.updateProperty('showError', true)
          this.updateProperty("profileUpdateConfirmed", false);
        } else {
          this.userProfile.username = this.userProfileForm.username;
          this.userProfile.email = this.userProfileForm.email;
          this.userProfile.group = this.userProfileForm.group;
          this.updateFormProperty("firstName", "");
          this.updateFormProperty("lastName", "");
          this.updateFormProperty("username", "");
          this.updateFormProperty("email", "");
          this.updateFormProperty("bio", "");
          this.updateFormProperty("group", "");
          this.updateProperty("profileUpdateConfirmed", true);
          // this.updateProperty('showError', false)
        }
        this.updateProperty("formSent", false);
        this.updateProperty("isLoading", false);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
      // this.updateProperty('showError', true)
      this.updateProperty("formSent", false);
      this.updateProperty("isLoading", false);
    }
  };

  @action("change gender") changeGender(gender) {
    console.log("Value: ", gender);
    console.log("Form pre: ", this.userProfileForm.gender);
    this.userProfileForm.gender = gender;
    console.log("Form post: ", this.userProfileForm.gender);
  }

  @action("change daltonism") changeDaltonism(dalton) {
    console.log("Value: ", dalton);
    console.log("Form pre: ", this.userProfileForm.daltonism);
    this.userProfileForm.daltonism = dalton;
    console.log("Form post: ", this.userProfileForm.daltonism);
  }

}
export default UserProfileStore;
