import React, { Component } from "react";
import { observer, inject } from "mobx-react";
//import PropTypes from 'prop-types';
import MyInput from "../basicUI/MyInput";
import MyRadioButton from "../basicUI/MyRadioButton";
import MyTextArea from "../basicUI/MyTextArea";
import MyButton from "../basicUI/MyButton";
import "../App.css";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import TextArea from "../basicUI/MyTextArea";

@inject("processStore", "groupAdsStore", "userStore", "groupEditorStore", "navigationStore")
@observer
class MyModal extends Component {
  onCancel = () => {
    this.props.processStore.closing = false;
    this.props.processStore.saving = false;
    this.props.groupAdsStore.closing = false;
    this.props.groupAdsStore.saving = false;
    this.props.processStore.notFinished = false;
  };

  onClose = () => {
    if (this.props.groupAdsStore.openModal) {
      this.props.groupAdsStore.closed();
      //this.select()
    } else this.props.processStore.closed();
  };

  onClosing = (show) => {
    if (this.props.groupAdsStore.openModal) {
      this.props.groupAdsStore.close(show);
      //this.select()
    } else this.props.processStore.close(show);
  };

  onAddTask = () => {
    this.props.processStore.createNewTask();
  };

  saveProcess = () => {
    this.props.processStore.addNewProcess();
  };

  savingProcess = () => {
    if (this.props.groupAdsStore.openModal) {
      this.props.groupAdsStore.saving = true;
    } else this.props.processStore.saving = true;
  };

  saveTask = () => {
    this.props.processStore.addNewTask();
    this.props.processStore.showHideTask2 = false;
  };
  saveCurrentTask = (task) => {
    this.props.processStore.editTask(task);
  };

  deleteTask = (task) => {
    this.props.processStore.deleteTask(task);
  };

  editTask = (task) => {
    this.props.processStore.showHideTask2 = true;
    task.editing = true;
  };

  saveCurrentProcess = () => {
    this.props.processStore.addExistentProcess();
  };

  saveCurrentReport = (task, show) => {
    this.props.processStore.addExistentReport(task, show);
  };

  select = () => {
    let elem2 = document.getElementById("inputGroupSelect01");
    if (elem2) {
      for (let j = 0; j < elem2.length; j++) {
        var option = elem2.options[j];
        if (option.value === this.props.groupAdsStore.statesGroup.value) {
          option.selected = true;
        } else option.selected = false;
      }
    }
  };

  //closing = true;
  //   onClosing2 = (e) => {
  //     this.props.processStore.close2();
  // }
  modGa = false;

  NewProcessModal = () => {
    let sh = this.props.processStore.showHideModal;
    const showHide = sh ? "modalw display-block" : "modalw display-none";
    const closing =
      this.props.processStore.closing || this.props.processStore.saving
        ? "modalClose display-block container"
        : "display-none";
    const notFinished = this.props.processStore.notFinished
      ? "modalClose display-block container"
      : "display-none ";
    const showTaskInput = this.props.processStore.showHideTask
      ? "display-block"
      : "display-none";
    const closeMsg = i18n.t("mymodal.closeSure?");
    const saveMsg = i18n.t("mymodal.saveSure?");
    const notFinishMsg = i18n.t("mymodal.continue");
    return (
      <>
        <div className={showHide}>
          <section className="modal-main">
            <>
              {this.props.processStore.notFinished ? (
                <div className={notFinished}>
                  <section className="modal-confirm">
                    <>
                      <div className="container">
                        <form className="justify-content-center separar close-process-modal">
                          <p>{notFinishMsg}</p>
                          <MyButton
                            type="button"
                            className="btn btn-outline-danger"
                            text={i18n.t("mymodal.understand")}
                            onClick={(e) => {
                              this.onCancel();
                            }}
                          />
                        </form>
                      </div>
                    </>
                  </section>
                </div>
              ) : (
                <div className={closing}>
                  <section className="modal-confirm">
                    <>
                      <div className="container">
                        <form className="justify-content-center separar close-process-modal">
                          {this.props.processStore.closing ? (
                            <p>{closeMsg}</p>
                          ) : this.props.processStore.saving ? (
                            <p>{saveMsg}</p>
                          ) : null}
                          <MyButton
                            type="button"
                            className="btn btn-outline-danger"
                            text={i18n.t("mymodal.cancel")}
                            onClick={(e) => {
                              this.onCancel();
                            }}
                          />
                          <MyButton
                            type="button"
                            className="btn btn-info"
                            text={
                              this.props.processStore.closing
                                ? i18n.t("mymodal.close")
                                : this.props.processStore.saving
                                ? i18n.t("mymodal.save")
                                : "null"
                            }
                            onClick={
                              this.props.processStore.closing
                                ? (e) => {
                                    this.onClosing();
                                  }
                                : this.props.processStore.saving
                                ? (e) => {
                                    this.saveProcess();
                                  }
                                : null
                            }
                          />
                        </form>
                      </div>
                    </>
                  </section>
                </div>
              )}
              <div className="container">
                <form className="justify-content-center separar add-process-modal">
                  <MyInput
                    className="input-add-process"
                    visible="false"
                    type="text"
                    name="title"
                    label="Title"
                    placeholder={i18n.t("mymodal.title")}
                    value={this.props.processStore.CurrentProcess.title}
                    onChange={this.props.processStore.updateFormProperty}
                  />
                  <MyButton
                    className="btn btn-info"
                    type="button"
                    text={i18n.t("mymodal.+task")}
                    onClick={this.onAddTask}
                  />
                  <hr />
                  {this.props.processStore.CurrentProcess.task.map((task) => {
                    if (task.editing) {
                      return (
                        <div
                          key={task.id_task}
                          className={
                            this.props.processStore.showHideTask2
                              ? "display-block"
                              : "display-none"
                          }
                        >
                          <p>
                            <MyInput
                              className="input-add-process"
                              visible="false"
                              type="text"
                              name="name"
                              label="Title"
                              value={task.name}
                              onChange={(key, value) => {
                                this.props.processStore.updateEditProperty(
                                  key,
                                  value,
                                  task
                                );
                              }}
                            />
                            <MyButton
                              type="button"
                              className="btn btn-info"
                              text={i18n.t("mymodal.saveTask")}
                              onClick={(e) => {
                                this.saveCurrentTask(task);
                              }}
                            />
                          </p>
                        </div>
                      );
                    } else
                      return (
                        <div key={task.id_task}>
                          <p>
                            {task.name}
                            {task.name !== i18n.t("mymodal.allTask") && (
                              <MyButton
                                className="btn btn-outline-danger"
                                type="button"
                                text={i18n.t("mymodal.deleteTask")}
                                onClick={(e) => {
                                  this.deleteTask(task);
                                }}
                              />
                            )}
                            {task.name !== i18n.t("mymodal.allTask") && (
                              <MyButton
                                className="btn btn-outline-info"
                                type="button"
                                text={i18n.t("mymodal.editTask")}
                                onClick={(e) => {
                                  this.editTask(task);
                                }}
                              />
                            )}
                          </p>
                        </div>
                      );
                  })}
                  <div className={showTaskInput}>
                    <p>
                      <MyInput
                        className="input-add-process"
                        visible="false"
                        type="text"
                        name="name"
                        label="Task"
                        placeholder={i18n.t("mymodal.input")}
                        value={this.props.processStore.CurrentTask.name}
                        onChange={this.props.processStore.updateFormProperty}
                      />
                      <MyButton
                        type="button"
                        className="btn btn-info"
                        text={i18n.t("mymodal.add")}
                        onClick={this.saveTask}
                      />
                    </p>
                  </div>
                  <hr />
                  <MyButton
                    type="reset"
                    className="btn btn-outline-danger"
                    text={i18n.t("mymodal.cancel")}
                    onClick={(e) => {
                      this.onClose();
                    }}
                  />
                  <MyButton
                    className="btn btn-info"
                    type="reset"
                    text={i18n.t("mymodal.saveProcess")}
                    onClick={(e) => {
                      this.savingProcess();
                    }}
                  />
                </form>
              </div>
            </>
          </section>
        </div>
      </>
    );
  };

  EditProcessModal = () => {
    let sh = this.props.processStore.showHideModal;
    const showHide = sh ? "modalw display-block" : "modalw display-none";
    const showTaskInput = this.props.processStore.showHideTask
      ? "display-block"
      : "display-none";
    const closing =
      this.props.processStore.closing || this.props.processStore.saving
        ? "modalClose container display-block"
        : "display-none";
    const notFinished = this.props.processStore.notFinished
      ? "modalClose container display-block"
      : "display-none";
    const closeMsg = i18n.t("mymodal.closeSure?");
    const saveMsg = i18n.t("mymodal.saveSure?");
    const notFinishMsg = i18n.t("mymodal.continue");
    return (
      <div className={showHide}>
        <section className="modal-main">
          <>
            {this.props.processStore.notFinished ? (
              <div className={notFinished}>
                <section className="modal-confirm">
                  <>
                    <div className="container">
                      <form className="justify-content-center separar close-process-modal">
                        <p>{notFinishMsg}</p>
                        <MyButton
                          type="button"
                          className="btn btn-outline-danger"
                          text={i18n.t("mymodal.understand")}
                          onClick={(e) => {
                            this.onCancel();
                          }}
                        />
                      </form>
                    </div>
                  </>
                </section>
              </div>
            ) : (
              <div className={closing}>
                <section className="modal-confirm">
                  <>
                    <div className="container">
                      <form className="justify-content-center separar close-process-modal">
                        {this.props.processStore.closing ? (
                          <p>{closeMsg}</p>
                        ) : this.props.processStore.saving ? (
                          <p>{saveMsg}</p>
                        ) : null}
                        <MyButton
                          type="button"
                          className="btn btn-outline-danger"
                          text={i18n.t("mymodal.cancel")}
                          onClick={(e) => {
                            this.onCancel();
                          }}
                        />
                        <MyButton
                          type="button"
                          className="btn btn-info"
                          text={
                            this.props.processStore.closing
                              ? i18n.t("mymodal.close")
                              : this.props.processStore.saving
                              ? i18n.t("mymodal.save")
                              : "null"
                          }
                          onClick={
                            this.props.processStore.closing
                              ? (e) => {
                                  this.onClosing();
                                }
                              : this.props.processStore.saving
                              ? (e) => {
                                  this.saveCurrentProcess();
                                }
                              : null
                          }
                        />
                      </form>
                    </div>
                  </>
                </section>
              </div>
            )}
            <div className="container ">
              <form className="justify-content-center separar edit-process-modal">
                <MyInput
                  className="input-edit-process"
                  visible="true"
                  type="text"
                  name="title"
                  label="title"
                  value={this.props.processStore.CurrentProcessCopy.title}
                  onChange={(key, value) => {
                    this.props.processStore.updateEditProperty(key, value, 0);
                  }}
                />
                <MyButton
                  className="btn btn-info"
                  type="button"
                  text={i18n.t("mymodal.+task")}
                  onClick={this.onAddTask}
                />
                <hr />
                {this.props.processStore.CurrentProcessCopy.task.map((task) => {
                  if (task.editing) {
                    return (
                      <div
                        key={task.id_task}
                        className={
                          this.props.processStore.showHideTask2
                            ? "display-block"
                            : "display-none"
                        }
                      >
                        <p>
                          <MyInput
                            className="input-edit-process"
                            visible="true"
                            type="text"
                            name="name"
                            label="name"
                            value={task.name}
                            onChange={(key, value) => {
                              this.props.processStore.updateEditProperty(
                                key,
                                value,
                                task
                              );
                            }}
                          />
                          <MyButton
                            type="button"
                            className="btn btn-info"
                            text={i18n.t("mymodal.save")}
                            onClick={(e) => {
                              this.saveCurrentTask(task);
                            }}
                          />
                        </p>
                      </div>
                    );
                  } else
                    return (
                      <div key={task.id_task}>
                        <p>
                          {task.name}
                          {task.name !== i18n.t("mymodal.allTask") && (
                            <MyButton
                              className="btn btn-outline-danger"
                              type="button"
                              text={i18n.t("mymodal.deleteTask")}
                              onClick={(e) => {
                                this.deleteTask(task);
                              }}
                            />
                          )}
                          {task.name !== i18n.t("mymodal.allTask") && (
                            <MyButton
                              className="btn btn-outline-info"
                              type="button"
                              text={i18n.t("mymodal.editTask")}
                              onClick={(e) => {
                                this.editTask(task);
                              }}
                            />
                          )}
                        </p>
                      </div>
                    );
                })}
                <div className={showTaskInput}>
                  <p>
                    <MyInput
                      className="input-edit-process"
                      visible="true"
                      type="text"
                      name="name"
                      label="name"
                      placeholder={i18n.t("mymodal.input")}
                      value={this.props.processStore.CurrentTask.name}
                      onChange={this.props.processStore.updateFormProperty}
                    />
                    <MyButton
                      type="button"
                      className="btn btn-info"
                      text={i18n.t("mymodal.add")}
                      onClick={this.saveTask}
                    />
                  </p>
                </div>
                <hr />
                <MyButton
                  className="btn btn-outline-danger"
                  type="reset"
                  text={i18n.t("mymodal.cancel")}
                  onClick={(e) => {
                    this.onClose();
                  }}
                />
                <MyButton
                  className="btn btn-info"
                  type="reset"
                  text={i18n.t("mymodal.saveProcess")}
                  onClick={(e) => {
                    this.savingProcess();
                  }}
                />
              </form>
            </div>
          </>
        </section>
      </div>
    );
  };

  EditTaskReportModal = () => {
    let sh = this.props.processStore.showHideModal;
    let showHide = sh ? "modalw display-block" : "modalw display-none";
    if (
      showHide === "modalw display-block" &&
      this.props.processStore.edit3 === true
    )
      showHide = "modalw display-block";
    const closing =
      this.props.processStore.closing || this.props.processStore.saving
        ? "modalClose container display-block"
        : "display-none";
    const closeMsg = i18n.t("mymodal.closeSure?");
    const saveMsg = i18n.t("mymodal.saveSure?");
    const showReport = this.props.processStore.edit
      ? "display-block"
      : "display-none";

    return (
      <div className={showHide}>
        <div className={showReport}>
          <section className="modal-main">
            <>
              <div className={closing}>
                <section className="modal-confirm ">
                  <>
                    <div className="container">
                      <form className="justify-content-center separar close-process-modal">
                        {this.props.processStore.closing ? (
                          <p>{closeMsg}</p>
                        ) : this.props.processStore.saving ? (
                          <p>{saveMsg}</p>
                        ) : null}
                        <MyButton
                          type="button"
                          className="btn btn-outline-danger"
                          text={i18n.t("mymodal.cancel")}
                          onClick={(e) => {
                            this.onCancel();
                          }}
                        />
                        <MyButton
                          type="button"
                          className="btn btn-info"
                          text={
                            this.props.processStore.closing
                              ? i18n.t("mymodal.close")
                              : this.props.processStore.saving
                              ? i18n.t("mymodal.save")
                              : "null"
                          }
                          onClick={
                            this.props.processStore.closing
                              ? (e) => {
                                  this.onClosing(false);
                                }
                              : this.props.processStore.saving
                              ? (e) => {
                                  this.saveCurrentReport(
                                    this.props.processStore.CurrentTask,
                                    showHide
                                  );
                                }
                              : null
                          }
                        />
                      </form>
                    </div>
                  </>
                </section>
              </div>
              <div className="container ">
                <form className="justify-content-center separar">
                  <MyTextArea
                    visible="true"
                    name="report"
                    label={i18n.t("mymodal.report")}
                    value={this.props.processStore.CurrentTask.report}
                    onChange={(key, value) => {
                      this.props.processStore.updateEditProperty(
                        key,
                        value,
                        this.props.processStore.CurrentTask
                      );
                    }}
                  />
                  <div style={{ maxWidth: "100%" }}>
                    <MyInput
                      type="checkbox"
                      id="check"
                      style={{ margin: ".4rem" }}
                      onChange={(key, value) => {
                        console.log(document.getElementById("check").checked);
                      }}
                    />
                    <label>{"Tancar la tasca?"}</label>

                    {/*</form><label htmlFor="check">Tancar la tasca</label>*/}

                    <MyButton
                      className="btn btn-outline-danger"
                      type="reset"
                      text={i18n.t("mymodal.cancel")}
                      onClick={(e) => {
                        this.onClose();
                      }}
                    />
                    <MyButton
                      className="btn btn-info"
                      type="reset"
                      text={i18n.t("mymodal.saveReport")}
                      onClick={(e) => {
                        this.savingProcess();
                      }}
                    />
                  </div>
                  <br />
                  <br />
                </form>
              </div>
            </>
          </section>
        </div>
      </div>
    );
  };

  EditGeneralReportModal = () => {
    let sh = this.props.processStore.showHideModal;
    let showHide = sh ? "modalw display-block" : "modalw display-none";
    if (
      showHide === "modalw display-block" &&
      this.props.processStore.edit3 === true
    )
      showHide = "modalw display-block";
    const closing =
      this.props.processStore.closing || this.props.processStore.saving
        ? "modalClose container display-block"
        : "display-none";
    const showGen = this.props.processStore.s
      ? "display-block"
      : "display-none";
    const closeMsg = i18n.t("mymodal.closeSure?");
    const saveMsg = i18n.t("mymodal.saveSure?");
    return (
      <div className={showHide}>
        <div className={showGen}>
          <section className="modal-main">
            <>
              <div className={closing}>
                <section className="modal-confirm">
                  <>
                    <div className="container">
                      <form className="justify-content-center separar close-process-modal">
                        {this.props.processStore.closing ? (
                          <p>{closeMsg}</p>
                        ) : this.props.processStore.saving ? (
                          <p>{saveMsg}</p>
                        ) : null}
                        <MyButton
                          type="button"
                          className="btn btn-outline-danger"
                          text={i18n.t("mymodal.cancel")}
                          onClick={(e) => {
                            this.onCancel();
                          }}
                        />
                        <MyButton
                          type="button"
                          className="btn btn-info"
                          text={
                            this.props.processStore.closing
                              ? i18n.t("mymodal.close")
                              : this.props.processStore.saving
                              ? i18n.t("mymodal.save")
                              : "null"
                          }
                          onClick={
                            this.props.processStore.closing
                              ? (e) => {
                                  this.onClosing(false);
                                }
                              : this.props.processStore.saving
                              ? (e) => {
                                  this.props.processStore.addExistentGenReport(
                                    this.props.processStore.CurrentProcess,
                                    showHide
                                  );
                                }
                              : null
                          }
                        />
                      </form>
                    </div>
                  </>
                </section>
              </div>
              <div className="container ">
                <form className="justify-content-center separar">
                  <MyTextArea
                    visible="true"
                    name="generalReport"
                    label={i18n.t("mymodal.greport")}
                    value={this.props.processStore.CurrentProcess.generalReport}
                    onChange={(key, value) => {
                      this.props.processStore.updateEditProperty(
                        key,
                        value,
                        this.props.processStore.CurrentProcess
                      );
                    }}
                  />
                  <MyButton
                    className="btn btn-outline-danger"
                    type="reset"
                    text={i18n.t("mymodal.cancel")}
                    onClick={(e) => {
                      this.onClose();
                    }}
                  />
                  <MyButton
                    className="btn btn-info"
                    type="reset"
                    text="Guardar Infome General"
                    onClick={(e) => {
                      this.savingProcess();
                    }}
                  />
                  <br /> <br />
                </form>
              </div>
            </>
          </section>
        </div>
      </div>
    );
  };

  FinishProcessModal = () => {
    let sh = this.props.processStore.showHideModal;
    const showHide = sh ? "modalw display-block" : "modalw display-none";
    const showEnd = this.props.processStore.finish
      ? "display-block"
      : "display-none";
    return (
      <>
        <div className={showHide}>
          <div className={showEnd}>
            <section className="modal-main">
              <>
                <div className="container ">
                  <form className="justify-content-center separar">
                    <div>
                      <h3>{this.props.processStore.CurrentProcess.title} </h3>
                    </div>
                    <hr />
                    <div>
                      {" "}
                      {this.props.processStore.CurrentProcess.task.map(
                        (task) => {
                          return (
                            <div key={task.id_task}>
                              <h5>{task.name}</h5>
                              <p>{task.report}</p>
                            </div>
                          );
                        }
                      )}{" "}
                    </div>

                    <div>
                      <h5>{i18n.t("mymodal.greport")}</h5>
                      <p>
                        {this.props.processStore.CurrentProcess.generalReport}
                      </p>
                    </div>

                    <MyButton
                      className="btn btn-outline-danger"
                      type="reset"
                      text={i18n.t("mymodal.cancel")}
                      onClick={(e) => {
                        this.onClose();
                      }}
                      disabled /* a l'espera de què posar */
                    />

                    <MyButton
                      className="btn btn-info"
                      type="reset"
                      text={i18n.t("mymodal.finish")}
                      onClick={(e) => {
                        this.props.processStore.saveProcessState(
                          this.props.processStore.CurrentProcess
                        );
                      }}
                    />
                  </form>
                </div>
              </>
            </section>
          </div>
        </div>
      </>
    );
  };

  /* Modal amb fons */
  ReportsModalForGA = () => {
    let sh = this.props.processStore.showHideModal;
    const showHide = sh ? "modalw modalTasks display-block " : "display-none";
    const closing =
      this.props.processStore.closing || this.props.processStore.saving
        ? "modalClose container display-block"
        : "display-none";
    const closeMsg = "Tancaràs la finestra. Estàs segur?";
    const saveMsg = i18n.t("mymodal.saveSure?");

    return (
      <div className={showHide} style={{ zIndex: "10000" }}>
        <div className="justify-content-center">
          <section className="modal-main">
            <>
              <div className={closing}>
                <section className="modal-confirm">
                  <>
                    <div className="container">
                      <form className="close-process-modal justify-content-center">
                        {this.props.processStore.closing ? (
                          <p>{closeMsg}</p>
                        ) : this.props.processStore.saving ? (
                          <p>{saveMsg}</p>
                        ) : null}
                        <MyButton
                          type="button"
                          className="btn btn-outline-danger"
                          text={i18n.t("mymodal.cancel")}
                          onClick={(e) => {
                            this.onCancel();
                          }}
                        />
                        <MyButton
                          type="button"
                          className="btn btn-info"
                          text={
                            this.props.processStore.closing
                              ? i18n.t("mymodal.close")
                              : this.props.processStore.saving
                              ? i18n.t("mymodal.save")
                              : "null"
                          }
                          onClick={
                            this.props.processStore.closing
                              ? (e) => {
                                  this.onClosing(true);
                                }
                              : this.props.processStore.saving
                              ? (e) => {
                                  this.saveCurrentReport(
                                    this.props.processStore.CurrentTask
                                  );
                                }
                              : null
                          }
                        />
                      </form>
                    </div>
                  </>
                </section>
              </div>
              <div className="container ">
                <form className="">
                  <div className="modal-header">
                    <h5>
                      {i18n.t("mymodal.processGA") +
                        this.props.processStore.group}
                    </h5>
                  </div>
                  <div className="modal-body groupAd-view">
                    <div id="task-content" className="col-md-12">
                      <div id="tasks-table" className="row tasks-table">
                        {this.props.processStore.processList.length > 0 ? (
                          this.props.processStore.processList.map((process) => {
                            return (
                              <table
                                key={process._id}
                                className="table table-hover table-borderless"
                              >
                                <thead key={process._id}>
                                  <tr>
                                    <th>{process.title}</th>
                                  </tr>
                                  <tr>
                                    <th> </th>
                                    <th style={{ textAlign: "center" }}>
                                     {i18n.t("mymodal.taskName")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                     {i18n.t("mymodal.lastMod")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      {i18n.t("mymodal.summary'")}
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {process.task.map((task) => {
                                    return (
                                      <tr key={task.id_task}>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "10%",
                                          }}
                                        >
                                          {" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "15%",
                                          }}
                                        >
                                          {task.name}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "25%",
                                          }}
                                        >
                                          {this.props.processStore.convertToFormatTable(
                                            task.lastModified
                                          )}
                                        </td>
                                        {task.report.length >= 30 ? (
                                          <td
                                            style={{
                                              textAlign: "center",
                                              width: "40%",
                                            }}
                                          >
                                            {task.report.substring(0, 30)}...
                                          </td>
                                        ) : (
                                          <td
                                            style={{
                                              textAlign: "center",
                                              width: "40%",
                                            }}
                                          >
                                            {task.report.substring(0, 30)}
                                          </td>
                                        )}
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "10%",
                                          }}
                                        >
                                          <MyButton
                                            data-toggle="modal"
                                            name="Edita la tasca"
                                            data-target="#editModal"
                                            className="btn btn-outline-info"
                                            type="button"
                                            text={
                                              <svg
                                                data-name="fas fa-pencil"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 63.72 63.68"
                                              >
                                                <path
                                                  class="cls-1"
                                                  d="M12.1,72.21a5.05,5.05,0,0,1-1.69,0,3,3,0,0,1-2.16-1.81c0-.09,0-.21-.15-.27V70a.2.2,0,0,0,0-.07,7.59,7.59,0,0,1,.05-1.26c.25-1.42.49-2.84.73-4.26.05-.25.1-.49.14-.74.19-1.09.37-2.18.55-3.27.27-1.3.49-2.61.69-3.92.14-.75.28-1.49.4-2.24a1.31,1.31,0,0,1,.42-.81c.33-.29.62-.63.93-1a.06.06,0,0,0,0,0l4-4,0,0c1.34-1.3,2.66-2.61,3.95-4s2.65-2.6,3.94-3.94l.05-.05q2-1.94,4-3.95l0-.05,4-3.95.06-.05,3.88-3.88,0,0c1.34-1.3,2.65-2.61,4-4l0-.06,3.22-3.19a3.92,3.92,0,0,1,.78-.63l.15-.06h.8a3,3,0,0,1,1,.67c.66.67,1.34,1.32,2,2l1.29,1.29,2.71,2.71,1.32,1.36c.88.92,1.77,1.82,2.7,2.69l1.17,1.16,2.06,2.08a2.78,2.78,0,0,1,.71,1v.72a.29.29,0,0,0-.07.22,3.71,3.71,0,0,1-.59.72c-1.09,1.1-2.18,2.19-3.26,3.29q-2,1.92-3.93,3.93l-.08.08c-1.34,1.28-2.64,2.59-3.93,3.92l-.08.08q-2,1.92-3.93,3.93t-4,4l-.94.91-3,3-.09.09C34.59,61.72,33.28,63,32,64.36h0q-2,2-4,4l-.08.07-1,1a.76.76,0,0,1-.44.23L24,70.11l-.86.14L20,70.83,16,71.5c-.41.07-.81.13-1.21.21C13.92,71.88,13,72,12.1,72.21ZM16,55.41h-.58c-1,0-1,0-1.13,1-.05.24-.1.48-.14.71-.19,1.08-.37,2.15-.55,3.22a13.45,13.45,0,0,1-.38,1.91A1.65,1.65,0,0,0,14,64.34l0,0c.65.68,1.31,1.34,2,2l.78.8a.31.31,0,0,0,.32.11L20,66.74l.89-.14L24,66l.79-.11c.16,0,.22-.09.22-.26,0-.44,0-.88,0-1.31,0-.67,0-1.35,0-2,0-1,0-1-1-.95H20c-.23,0-.46,0-.69,0s-.29,0-.28-.26a6.45,6.45,0,0,0,0-.76q0-2,0-3.93c0-.27,0-.53,0-.79s-.06-.23-.23-.23ZM27.62,44.35l-1.19,1.16c-1,1-2.06,2-3,3.07a1.74,1.74,0,0,0,2.71,2.18L45.05,31.84a2.05,2.05,0,0,0,.39-.49,1.66,1.66,0,0,0,0-1.73,1.62,1.62,0,0,0-1.47-.84,1.76,1.76,0,0,0-1.37.61L29.88,42.06l-2,2A.83.83,0,0,0,27.62,44.35Z"
                                                  transform="translate(-8.08 -8.62)"
                                                />
                                                <path
                                                  class="cls-1"
                                                  d="M55.88,9.13l1.48-.45a7,7,0,0,1,2.49.13,6.48,6.48,0,0,1,2.85,1.66l1.11,1.09,1.07,1.07c1,1,1.94,2,3,2.94l1,1c.18.19.36.39.55.57a7.28,7.28,0,0,1,2.28,3.43l.14.58a12.29,12.29,0,0,1,0,1.82,12.65,12.65,0,0,1-.48,1.59A7.07,7.07,0,0,1,70,26.36l-2.1,2.11-.07.07c-1.12,1.11-2.25,2.21-3.36,3.33a1.65,1.65,0,0,1-1.74.53,4.19,4.19,0,0,1-.84-.67L60,29.85l-1.31-1.31-2.79-2.78-1.21-1.22q-1.36-1.44-2.79-2.79l-1.22-1.21-.45-.48A20.09,20.09,0,0,1,48,17.63a2.06,2.06,0,0,1,.82-1.95c1-1,2-2,3-3.06l.06-.06C52.42,12,53,11.5,53.51,11A8.28,8.28,0,0,1,55.88,9.13Z"
                                                  transform="translate(-8.08 -8.62)"
                                                />
                                              </svg>
                                            }
                                            onClick={(e) => {
                                              this.onClosing(false);
                                              this.props.processStore.openTaskReport(
                                                e,
                                                process.title,
                                                process._id,
                                                process.task,
                                                task,
                                                process.generalReport,
                                                process.finished,
                                                this.props.processStore.group
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td className="report-btns" colSpan="5">
                                      <div id="report-btns">
                                        <MyButton
                                          // className="btn-outline-info "
                                          data-toggle="modal"
                                          data-target="#editModal"
                                          className="btn btn-outline-info"
                                          type="button"
                                          text={i18n.t("mymodal.greport")}
                                          onClick={(e) => {
                                            this.onClosing(false);
                                            this.props.processStore.openGenReport(
                                              e,
                                              process.title,
                                              process._id,
                                              process.task,
                                              process.generalReport,
                                              process.finished,
                                              this.props.processStore.group
                                            );
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            );
                          })
                        ) : (
                          <p
                            className="invalid"
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            {i18n.t("mymodal.noProcess")}{" "}
                            <a
                              href={"groupAds/" + this.props.processStore.group}
                              style={{ color: "red" }}
                            >
                              {i18n.t("mymodal.go")}
                            </a>{" "}
                            {i18n.t("mymodal.update")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <MyButton
                      className="btn btn-outline-danger"
                      type="reset"
                      text={i18n.t("mymodal.cancel")}
                      onClick={(e) => {
                        this.onClose();
                      }}
                    />
                  </div>
                </form>
              </div>
            </>
          </section>
        </div>
      </div>
    );
  };
  /* MouseOver del in process */
  ReportsModalForGATasks = () => {
    let sh = this.props.processStore.showHideModal;
    const showHide = sh
      ? "modalHover display-block"
      : "modalHover display-none";
    const showAll = this.props.processStore.edit4
      ? "display-block"
      : "display-none";
    return (
      <div className={showHide}>
        <div className={showAll}>
          <section className="modal-main">
            <div className="card container">
              <form className="justify-content-center">
                {this.props.processStore.processList.length > 0 ? (
                  this.props.processStore.processList.map((process) => {
                    return process.task.map((tasks) => {
                      return (
                        <div key={tasks.name}>
                          {tasks.report.length > 0 ? (
                            <p>
                              <span
                                style={{
                                  color: "green",
                                  position: "relative",
                                  top: "-2px",
                                }}
                              >
                                &#x25C9;
                              </span>{" "}
                              {tasks.name}{" "}
                            </p>
                          ) : (
                            <p>
                              <span
                                style={{
                                  color: "darkgrey",
                                  position: "relative",
                                  top: "-2px",
                                }}
                              >
                                &#x25C9;
                              </span>{" "}
                              {tasks.name}
                            </p>
                          )}
                        </div>
                      );
                    });
                  })
                ) : (
                  <p className="invalid" style={{ textAlign: "center" }}>
                    {" "}
                    {i18n.t("mymodal.noProcess")}{" "}
                    <a
                      href={"groupAds/" + this.props.processStore.group}
                      style={{ color: "red" }}
                    >
                      {i18n.t("mymodal.go")}
                    </a>{" "}
                    {i18n.t("mymodal.update")}
                  </p>
                )}
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  };

  NewModal = () => {
    return (
      <div className="modalw display-none">
        <div className="display-none">
          <section className="modal-main">
            <div className="container ">
              <form className="justify-content-center separar"></form>
            </div>
          </section>
        </div>
      </div>
    );
  };

  gaModal = () => {
    let sh = this.props.groupAdsStore.showHideModal;
    let showHide = sh ? "modalw display-block" : "modalw display-none";
    const closing =
      this.props.groupAdsStore.closing || this.props.groupAdsStore.saving
        ? "modalClose container display-block"
        : "display-none";
    const showGen = this.props.groupAdsStore.edit2
      ? "display-block"
      : "display-none";
    const closeMsg = i18n.t("mymodal.closeSure?");
    const saveMsg = i18n.t("mymodal.saveSure?");
    return (
      <div className={showHide}>
        <div className={showGen}>
          <section className="modal-main">
            <>
              <div className={closing}>
                <section className="modal-confirm">
                  <>
                    <div className="container">
                      <form className="justify-content-center separar close-process-modal">
                        {this.props.groupAdsStore.closing ? (
                          <p>{closeMsg}</p>
                        ) : this.props.groupAdsStore.saving ? (
                          <p>{saveMsg}</p>
                        ) : null}
                        <MyButton
                          type="button"
                          className="btn btn-outline-danger"
                          text={i18n.t("mymodal.cancel")}
                          onClick={(e) => {
                            this.onCancel();
                          }}
                        />
                        <MyButton
                          type="button"
                          className="btn btn-info"
                          text={
                            this.props.groupAdsStore.closing
                              ? i18n.t("mymodal.close")
                              : this.props.groupAdsStore.saving
                              ? i18n.t("mymodal.save")
                              : "null"
                          }
                          onClick={
                            this.props.groupAdsStore.closing
                              ? (e) => {
                                  this.onClosing(
                                    this.props.groupAdsStore.editing
                                  );
                                }
                              : this.props.groupAdsStore.saving
                              ? (e) => {
                                  this.props.groupAdsStore.addExistentNotes(
                                    this.props.groupAdsStore.notesForm,
                                    this.props.groupAdsStore.editing
                                  );
                                }
                              : null
                          }
                        />
                      </form>
                    </div>
                  </>
                </section>
              </div>
              <div className="container ">
                <form className="justify-content-center separar">
                  <MyTextArea
                    visible="true"
                    name="notesForm"
                    label={i18n.t("mymodal.remark")}
                    value={this.props.groupAdsStore.notesForm}
                    onChange={(key, value) => {
                      this.props.groupAdsStore.updateProperty(key, value);
                    }}
                  />
                  <MyButton
                    className="btn btn-outline-danger"
                    type="reset"
                    text={i18n.t("mymodal.cancel")}
                    onClick={(e) => {
                      this.onClose();
                    }}
                  />
                  <MyButton
                    className="btn btn-info"
                    type="reset"
                    text={i18n.t("mymodal.saveRemark")}
                    onClick={(e) => {
                      this.savingProcess();
                    }}
                  />
                  <br /> <br />
                </form>
              </div>
            </>
          </section>
        </div>
      </div>
    );
  };

  addStateModal = () => {
    let sh = this.props.groupAdsStore.showHideModal;
    let showHide = sh ? "modalw display-block" : "modalw display-none";
    const closing =
      this.props.groupAdsStore.closing || this.props.groupAdsStore.saving
        ? "modalClose container display-block"
        : "display-none";
    const showGen = this.props.groupAdsStore.edit2
      ? "display-block"
      : "display-none";
    const closeMsg = i18n.t("mymodal.closeSure?");
    const saveMsg = i18n.t("mymodal.saveSure?");
    return (
      <>
        <div className={showHide}>
          <div className={showGen}>
            <section className="modal-main">
              <>
                <div className={closing}>
                  <section className="modal-confirm">
                    <>
                      <div className="container">
                        <form className="justify-content-center separar close-process-modal">
                          {this.props.groupAdsStore.closing ? (
                            <p>{closeMsg}</p>
                          ) : this.props.groupAdsStore.saving ? (
                            <p>{saveMsg}</p>
                          ) : null}
                          <MyButton
                            type="button"
                            className="btn btn-outline-danger"
                            text={i18n.t("mymodal.cancel")}
                            onClick={(e) => {
                              this.onCancel();
                            }}
                          />
                          <MyButton
                            type="button"
                            className="btn btn-info"
                            text={
                              this.props.groupAdsStore.closing
                                ? i18n.t("mymodal.close")
                                : this.props.groupAdsStore.saving
                                ? i18n.t("mymodal.save")
                                : "null"
                            }
                            onClick={
                              this.props.groupAdsStore.closing
                                ? (e) => {
                                    this.onClosing(false);
                                  }
                                : this.props.groupAdsStore.saving
                                ? (e) => {
                                    this.props.groupAdsStore.addExistentNotes(
                                      this.props.groupAdsStore.notesForm
                                    );
                                  }
                                : null
                            }
                          />
                        </form>
                      </div>
                    </>
                  </section>
                </div>
                <div className="container ">
                  <form className="justify-content-center separar">
                    <MyInput
                      className="input-add-process"
                      visible="false"
                      type="text"
                      name="name"
                      label="Name"
                      placeholder="Nombre"
                      value={this.props.processStore.CurrentProcess.title}
                      onChange={this.props.processStore.updateFormProperty}
                    />
                    <MyButton
                      className="btn btn-info"
                      type="button"
                      text={i18n.t("mymodal.+task")}
                      onClick={this.onAddTask}
                    />
                    <hr />
                    <MyInput
                      className="input-add-process"
                      visible="false"
                      type="text"
                      name="abrv"
                      label="abrv"
                      placeholder="Abreviatura"
                      value={this.props.processStore.CurrentProcess.title}
                      onChange={this.props.processStore.updateFormProperty}
                    />
                    <MyTextArea
                      visible="true"
                      name="notesForm"
                      label={i18n.t("mymodal.greport")}
                      value={this.props.groupAdsStore.notesForm}
                      onChange={(key, value) => {
                        this.props.groupAdsStore.updateProperty(key, value);
                      }}
                    />
                    <MyButton
                      className="btn btn-outline-danger"
                      type="reset"
                      text={i18n.t("mymodal.cancel")}
                      onClick={(e) => {
                        this.onClose();
                      }}
                    />
                    <MyButton
                      className="btn btn-info"
                      type="reset"
                      text={i18n.t("mymodal.greport")}
                      onClick={(e) => {
                        this.savingProcess();
                      }}
                    />
                    <br /> <br />
                  </form>
                </div>
              </>
            </section>
          </div>
        </div>
        {/* this.select() */}
      </>
    );
  };

  //style={{ top: this.props.groupAdsStore.mouseP.y, left: this.props.groupAdsStore.mouseP.x }}

  NotesModalForGA = () => {
    /* MouseOver del in process */
    let sh = this.props.groupAdsStore.showModal;
    const showHide = sh
      ? "modalHover display-block"
      : "modalHover display-none";
    const showAll = this.props.groupAdsStore.openeningModal
      ? "display-block"
      : "display-none";
    return (
      <div
        className={showHide}
        style={{
          top: JSON.stringify(this.props.groupAdsStore.mouseP[1] + 80) + "px",
          left: JSON.stringify(this.props.groupAdsStore.mouseP[0]) + "px",
        }}
      >
        <div className={showAll}>
          <section className="modal-main">
            <div className="card container">
              <form className="justify-content-center">
                {this.props.groupAdsStore.statesGroup &&
                this.props.groupAdsStore.statesGroup.name ? (
                  <div key={this.props.groupAdsStore.statesGroup.name}>
                    <p>
                      {i18n.t("ga.status.status") +
                        ": " +
                        i18n.t(
                          "ga.status.statusOptions." +
                            this.props.userStore.getUser().group +
                            "." +
                            this.props.groupAdsStore.statesGroup.name +
                            "." +
                            this.props.groupAdsStore.statesGroup.name +
                            "Write"
                        )}
                      <br />
                      {i18n.t("ga.status.situation") + ": "}{" "}
                      {this.props.groupAdsStore.statesGroup.value &&
                      this.props.groupAdsStore.statesGroup.value !== null
                        ? i18n.t(
                            "ga.status.statusOptions." +
                              this.props.userStore.getUser().group +
                              "." +
                              this.props.groupAdsStore.statesGroup.name +
                              "." +
                              this.props.groupAdsStore.statesGroup.value
                          )
                        : "-"}
                      <br />
                      {i18n.t("ga.status.remark") + ": "}
                      <span
                        style={{
                          color:
                            this.props.groupAdsStore.statesGroup.message &&
                            this.props.groupAdsStore.statesGroup.message
                              .notes &&
                            this.props.groupAdsStore.statesGroup.message.notes
                              .length > 0
                              ? "green"
                              : "grey",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        &#x25C9;
                      </span>{" "}
                      <br />
                      {this.props.groupAdsStore.workingUserAd === this.props.userStore.getUser().username ||
                      this.props.userStore.getUser().username in this.props.groupAdsStore.workingUserAd
                        ? ""
                        : i18n.t("mymodal.user") +
                          ": " +
                          this.props.groupAdsStore.workingUserAd}
                    </p>
                  </div>
                ) : (
                  <p
                    className="invalid"
                    style={{ textAlign: "center", position: "relative" }}
                  >
                    {this.props.groupAdsStore.workingUserAd !==
                    this.props.userStore.getUser().username || !(this.props.userStore.getUser().username in this.props.groupAdsStore.workingUserAd)? (
                      this.props.groupAdsStore.workingUserAd !== undefined &&
                      this.props.groupAdsStore.workingUserAd !== "-" && this.props.groupAdsStore.workingUserAd.length > 0 ? (
                        i18n.t("mymodal.noStateAndNotUser") +
                        this.props.groupAdsStore.workingUserAd
                      ) : (
                        i18n.t("mymodal.noStateAndUserUndefined")
                      )
                    ) : (
                      <>
                        {i18n.t("mymodal.noState")}{" "}
                        <a
                          href={"groupAds/" + this.props.groupAdsStore.group}
                          style={{ color: "red" }}
                        >
                          {i18n.t("mymodal.go")}
                        </a>{" "}
                        {i18n.t("mymodal.toModify")}
                      </>
                    )}
                  </p>
                )}
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  };

  NotesEditModalForGA = () => {
    let sh = this.props.groupAdsStore.showHideModal;
    const showHide = sh ? "modalw modalTasks display-block " : "display-none";
    const closing =
      this.props.groupAdsStore.closing || this.props.groupAdsStore.saving
        ? "modalClose container display-block"
        : "display-none";
    const closeMsg = i18n.t("mymodal.closeSure?");
    const saveMsg = i18n.t("mymodal.saveSure?");

    return (
      <div className={showHide} style={{ zIndex: "10000" }}>
        {this.props.groupAdsStore.statesGroup &&
        this.props.groupAdsStore.statesGroup.name ? (
          <div className="justify-content-center">
            <section className="modal-main">
              <>
                <div className={closing}>
                  <section className="modal-confirm">
                    <>
                      <div className="container">
                        <form className="close-process-modal justify-content-center">
                          {this.props.groupAdsStore.closing ? (
                            <p>{closeMsg}</p>
                          ) : this.props.groupAdsStore.saving ? (
                            <p>{saveMsg}</p>
                          ) : null}
                          <MyButton
                            type="button"
                            className="btn btn-outline-danger"
                            text={i18n.t("mymodal.cancel")}
                            onClick={(e) => {
                              this.onCancel();
                            }}
                          />
                          <MyButton
                            type="button"
                            className="btn btn-info"
                            text={
                              this.props.groupAdsStore.closing
                                ? i18n.t("mymodal.close")
                                : this.props.groupAdsStore.saving
                                ? i18n.t("mymodal.save")
                                : "null"
                            }
                            onClick={
                              this.props.groupAdsStore.closing
                                ? (e) => {
                                    this.onClosing(false);
                                  }
                                : this.props.groupAdsStore.saving
                                ? (e) => {
                                    this.props.groupAdsStore.addExistentNotes(
                                      this.props.groupAdsStore.notes
                                    );
                                  }
                                : null
                            }
                          />
                        </form>
                      </div>
                    </>
                  </section>
                </div>
                <div className="container ">
                  <form className="">
                    <div className="modal-body groupAd-view">
                      <div id="status-content" className="col-md-12">
                        <div id="status-table" className="row status-table">
                          <table
                            key="Status_Table12"
                            className="table table-hover table-borderless"
                          >
                            <thead key="Status_Head12">
                              <tr>
                                <th style={{ textAlign: "center" }}>
                                  {i18n.t("ga.status.status")}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {i18n.t("ga.status.situation")}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {i18n.t("ga.status.remark")}
                                  <br></br>
                                  <p style={{ fontSize: "0.6rem" }}>
                                    {i18n.t("ga.status.lastEdition") + ": "}{" "}
                                    {this.props.groupAdsStore.statesGroup
                                      .message &&
                                    this.props.groupAdsStore.statesGroup.message
                                      .date
                                      ? this.props.groupAdsStore.convertToFormatTable(
                                          this.props.groupAdsStore.statesGroup
                                            .message.date
                                        )
                                      : "-"}
                                    {", " + i18n.t("ga.status.user") + ": "}{" "}
                                    {this.props.groupAdsStore.statesGroup
                                      .message &&
                                    this.props.groupAdsStore.statesGroup.message
                                      .username
                                      ? this.props.groupAdsStore.statesGroup
                                          .message.username
                                      : "-"}
                                  </p>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr key="State_Key">
                                <td
                                  style={{
                                    textAlign: "left",
                                    width: "20%",
                                  }}
                                >
                                  {this.props.groupAdsStore.states.map(
                                    (state, i) => {
                                      return (
                                        <div key={"key_" + state.name}>
                                          <MyRadioButton
                                            id={"id_" + state.name}
                                            name={"name_" + state.name}
                                            value={"value_" + state.name}
                                            checked={
                                              state.name ===
                                              this.props.groupAdsStore
                                                .statesGroup.name
                                                ? true
                                                : false
                                            }
                                            //labelValue={state.name}
                                            labelValue={
                                              this.props.userStore.getUser() &&
                                              this.props.userStore.getUser()
                                                .group
                                                ? i18n.t(
                                                    "ga.status.statusOptions." +
                                                      this.props.userStore.getUser()
                                                        .group +
                                                      "." +
                                                      state.name +
                                                      "." +
                                                      state.name +
                                                      "Write"
                                                  )
                                                : ""
                                            }
                                            // style={
                                            //   this.props.groupAdsStore
                                            //     .userIsTheWorkingUser
                                            //     ? null
                                            //     : { opacity: "0.9"}
                                            // }
                                            // disabled={
                                            //   this.props.groupAdsStore
                                            //     .userIsTheWorkingUser
                                            //     ? false
                                            //     : true
                                            // }
                                            onChange={(e) => {
                                              this.props.groupAdsStore.radioChanged(
                                                i
                                              );
                                            }}
                                          ></MyRadioButton>
                                          <br />
                                        </div>
                                      );
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "20%",
                                  }}
                                >
                                  <select
                                    //defaultValue={this.props.groupAdsStore.statesGroup.value ? null : "choose"}
                                    defaultValue={
                                      this.props.groupAdsStore.statesGroup.value
                                        ? this.props.groupAdsStore.statesGroup
                                            .value
                                        : "choose"
                                    }
                                    className="custom-select"
                                    id="inputGroupSelect01"
                                    onChange={(e) => {
                                      this.props.groupAdsStore.selectChanged(
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="choose" disabled={true}>
                                      {i18n.t("profile.choose")}
                                    </option>

                                    {this.props.groupAdsStore.indexSituation >=
                                      0 &&
                                    this.props.groupAdsStore.indexSituation !==
                                      undefined
                                      ? this.props.groupAdsStore.states[
                                          this.props.groupAdsStore
                                            .indexSituation
                                        ].values.map((value, i) => {
                                          return (
                                            <option
                                              key={"key_" + value}
                                              value={value}
                                              //selected={value === this.props.groupAdsStore.statesGroup.value ? true : false}
                                              // disabled = {this.props.groupAdsStore
                                              //    .userIsTheWorkingUser
                                              //      ? false
                                              //      : true}
                                            >
                                              {this.props.userStore.getUser() &&
                                              this.props.userStore.getUser()
                                                .group
                                                ? i18n.t(
                                                    "ga.status.statusOptions." +
                                                      this.props.userStore.getUser()
                                                        .group +
                                                      "." +
                                                      this.props.groupAdsStore
                                                        .states[
                                                        this.props.groupAdsStore
                                                          .indexSituation
                                                      ].name +
                                                      "." +
                                                      value
                                                  )
                                                : ""}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                  {/* {this.select()} */}
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    width: "50%",
                                  }}
                                >
                                  {this.props.groupAdsStore.statesGroup.message
                                    ? this.props.groupAdsStore.statesGroup
                                        .message.notes
                                    : ""}
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "10%",
                                  }}
                                >
                                  <MyButton
                                    data-toggle="modal"
                                    name={i18n.t("ga.status.editRemark")}
                                    data-target="#editModal"
                                    className="btn btn-outline-info edit-process"
                                    // style={
                                    //   this.props.groupAdsStore
                                    //     .userIsTheWorkingUser
                                    //     ? null
                                    //     : { opacity: "0.5" }
                                    // }
                                    // disabled={
                                    //   this.props.groupAdsStore
                                    //     .userIsTheWorkingUser
                                    //     ? "false"
                                    //     : "true"
                                    // }
                                    type="button"
                                    text={
                                      <>
                                        <svg
                                          className="svg-icon-pencil-tasks-ga"
                                          data-name="fas fa-pencil-alt"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 63.72 63.68"
                                        >
                                          <path
                                            className="cls-1"
                                            d="M12.1,72.21a5.05,5.05,0,0,1-1.69,0,3,3,0,0,1-2.16-1.81c0-.09,0-.21-.15-.27V70a.2.2,0,0,0,0-.07,7.59,7.59,0,0,1,.05-1.26c.25-1.42.49-2.84.73-4.26.05-.25.1-.49.14-.74.19-1.09.37-2.18.55-3.27.27-1.3.49-2.61.69-3.92.14-.75.28-1.49.4-2.24a1.31,1.31,0,0,1,.42-.81c.33-.29.62-.63.93-1a.06.06,0,0,0,0,0l4-4,0,0c1.34-1.3,2.66-2.61,3.95-4s2.65-2.6,3.94-3.94l.05-.05q2-1.94,4-3.95l0-.05,4-3.95.06-.05,3.88-3.88,0,0c1.34-1.3,2.65-2.61,4-4l0-.06,3.22-3.19a3.92,3.92,0,0,1,.78-.63l.15-.06h.8a3,3,0,0,1,1,.67c.66.67,1.34,1.32,2,2l1.29,1.29,2.71,2.71,1.32,1.36c.88.92,1.77,1.82,2.7,2.69l1.17,1.16,2.06,2.08a2.78,2.78,0,0,1,.71,1v.72a.29.29,0,0,0-.07.22,3.71,3.71,0,0,1-.59.72c-1.09,1.1-2.18,2.19-3.26,3.29q-2,1.92-3.93,3.93l-.08.08c-1.34,1.28-2.64,2.59-3.93,3.92l-.08.08q-2,1.92-3.93,3.93t-4,4l-.94.91-3,3-.09.09C34.59,61.72,33.28,63,32,64.36h0q-2,2-4,4l-.08.07-1,1a.76.76,0,0,1-.44.23L24,70.11l-.86.14L20,70.83,16,71.5c-.41.07-.81.13-1.21.21C13.92,71.88,13,72,12.1,72.21ZM16,55.41h-.58c-1,0-1,0-1.13,1-.05.24-.1.48-.14.71-.19,1.08-.37,2.15-.55,3.22a13.45,13.45,0,0,1-.38,1.91A1.65,1.65,0,0,0,14,64.34l0,0c.65.68,1.31,1.34,2,2l.78.8a.31.31,0,0,0,.32.11L20,66.74l.89-.14L24,66l.79-.11c.16,0,.22-.09.22-.26,0-.44,0-.88,0-1.31,0-.67,0-1.35,0-2,0-1,0-1-1-.95H20c-.23,0-.46,0-.69,0s-.29,0-.28-.26a6.45,6.45,0,0,0,0-.76q0-2,0-3.93c0-.27,0-.53,0-.79s-.06-.23-.23-.23ZM27.62,44.35l-1.19,1.16c-1,1-2.06,2-3,3.07a1.74,1.74,0,0,0,2.71,2.18L45.05,31.84a2.05,2.05,0,0,0,.39-.49,1.66,1.66,0,0,0,0-1.73,1.62,1.62,0,0,0-1.47-.84,1.76,1.76,0,0,0-1.37.61L29.88,42.06l-2,2A.83.83,0,0,0,27.62,44.35Z"
                                            transform="translate(-8.08 -8.62)"
                                          />
                                          <path
                                            className="cls-1"
                                            d="M55.88,9.13l1.48-.45a7,7,0,0,1,2.49.13,6.48,6.48,0,0,1,2.85,1.66l1.11,1.09,1.07,1.07c1,1,1.94,2,3,2.94l1,1c.18.19.36.39.55.57a7.28,7.28,0,0,1,2.28,3.43l.14.58a12.29,12.29,0,0,1,0,1.82,12.65,12.65,0,0,1-.48,1.59A7.07,7.07,0,0,1,70,26.36l-2.1,2.11-.07.07c-1.12,1.11-2.25,2.21-3.36,3.33a1.65,1.65,0,0,1-1.74.53,4.19,4.19,0,0,1-.84-.67L60,29.85l-1.31-1.31-2.79-2.78-1.21-1.22q-1.36-1.44-2.79-2.79l-1.22-1.21-.45-.48A20.09,20.09,0,0,1,48,17.63a2.06,2.06,0,0,1,.82-1.95c1-1,2-2,3-3.06l.06-.06C52.42,12,53,11.5,53.51,11A8.28,8.28,0,0,1,55.88,9.13Z"
                                            transform="translate(-8.08 -8.62)"
                                          />
                                        </svg>
                                      </>
                                    }
                                    onClick={(e) => {
                                      this.props.groupAdsStore.openGenReport(
                                        e,
                                        true
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <MyButton
                        className="btn btn-outline-danger"
                        type="reset"
                        text={i18n.t("mymodal.close")}
                        onClick={(e) => {
                          //this.onClose();
                          this.onClosing(false);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </>
            </section>
          </div>
        ) : (
          <div className="justify-content-center">
            <section className="modal-main">
              <div className="card container">
                <form className="justify-content-center">
                  <p className="invalid" style={{ textAlign: "center" }}>
                    {" "}
                    {i18n.t("mymodal.noState")}{" "}
                    <a
                      href={"groupAds/" + this.props.groupAdsStore.group}
                      style={{ color: "red" }}
                    >
                      {i18n.t("mymodal.go")}
                    </a>{" "}
                    {i18n.t("mymodal.toModify")}
                  </p>
                  <div className="modal-footer">
                    <MyButton
                      className="btn btn-outline-danger"
                      type="reset"
                      text={i18n.t("mymodal.close")}
                      onClick={(e) => {
                        //this.onClose();
                        this.onClosing(false);
                      }}
                    />
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  };
  groupEditorModalNewGroupIndividual = () => {
    let sh = this.props.groupEditorStore.showModal;
    const showHide = sh
      ? "modalw edit-agrupacions-modal edit-agrupacions-confirmacio-modal display-block "
      : "display-none";

    return (
      <div className={showHide}>
        <div className="modal-dialog">
          <div className="modal-content">
            <p>{i18n.t("ng.sure?")}</p>
            <div style={{ padding: "0.5rem 1.5rem" }}>
              <TextArea
                visible="true"
                name="reason"
                label={i18n.t("ng.reason")}
                placeholder={i18n.t("ng.placeholder.insert")}
                value={this.props.groupEditorStore.reason}
                onChange={(key, value) => {
                  this.props.groupEditorStore.updateProperty(key, value);
                }}
              />
            </div>

            <div className="modal-dialog-buttons">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  this.props.groupEditorStore.updateProperty(
                    "showModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty("reason", null);
                }}
              >
                {i18n.t("ng.cancel")}
              </button>
              <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  this.props.groupEditorStore.updateProperty("showModal", true);
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    true
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    true
                  );
                }}
              >
                {i18n.t("ng.confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  groupEditorModalWorkingUserIndividual = () => {
    let sh = this.props.groupEditorStore.showModal;
    const showHide = sh
      ? "modalw edit-agrupacions-modal edit-agrupacions-confirmacio-modal display-block "
      : "display-none";

    return (
      <div className={showHide}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div style={{ padding: "0.5rem 1.5rem" }}>
              {i18n.t("ng.modalWorkingUserIndividual")}
            </div>

            <div className="modal-dialog-buttons">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={async () => {
                  this.props.groupEditorStore.updateProperty(
                    "showModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    false
                  );
                  let lastId = await this.props.groupEditorStore.createNewIndividualGroup(false);
                  this.props.groupEditorStore.updateProperty("reason", null);
                  await this.props.navigationStore.openInNewTab(
                    "/groupAds/" + lastId
                  );
                }}
              >
                {i18n.t("ng.noWorkingUser")}
              </button>
              <button
                type="button"
                className="btn btn-info"
                onClick={async () => {
                  this.props.groupEditorStore.updateProperty(
                    "showModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    false
                  );
                  let lastId = await this.props.groupEditorStore.createNewIndividualGroup(true);
                  this.props.groupEditorStore.updateProperty("reason", null);
                  await this.props.navigationStore.openInNewTab(
                    "/groupAds/" + lastId
                  );
                }}
                
              >
                {i18n.t("ng.workingUser")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  groupEditorModalNewGroup = () => {
    let sh = this.props.groupEditorStore.showModal;
    const showHide = sh
      ? "modalw edit-agrupacions-modal edit-agrupacions-confirmacio-modal display-block "
      : "display-none";

    return (
      <div className={showHide}>
        <div className="modal-dialog">
          <div className="modal-content">
            <p>{i18n.t("ng.sure?")}</p>
            <div style={{ padding: "0.5rem 1.5rem" }}>
              <TextArea
                visible="true"
                name="reason"
                label={i18n.t("ng.reason")}
                placeholder={i18n.t("ng.placeholder.insert")}
                value={this.props.groupEditorStore.reason}
                onChange={(key, value) => {
                  this.props.groupEditorStore.updateProperty(key, value);
                }}
              />
            </div>

            <div className="modal-dialog-buttons">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  this.props.groupEditorStore.updateProperty(
                    "showModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    false
                  );

                  this.props.groupEditorStore.updateProperty("reason", null);
                }}
              >
                {i18n.t("ng.cancel")}
              </button>
              <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  this.props.groupEditorStore.updateProperty("showModal", true);
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    true
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    false
                  );
                }}
              >
                {i18n.t("ng.confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  groupEditorModalWorkingUser = () => {
    let sh = this.props.groupEditorStore.showModal;
    const showHide = sh
      ? "modalw edit-agrupacions-modal edit-agrupacions-confirmacio-modal display-block "
      : "display-none";

    return (
      <div className={showHide}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div style={{ padding: "0.5rem 1.5rem" }}>
              {i18n.t("ng.modalWorkingUser")}
            </div>

            <div className="modal-dialog-buttons">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={async () => {
                  this.props.groupEditorStore.updateProperty(
                    "showModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    false
                  );
                  let lastId = await this.props.groupEditorStore.createNewGroup(false);
                  this.props.groupEditorStore.updateProperty("reason", null);
                  await this.props.navigationStore.openInNewTab(
                    "/groupAds/" + lastId
                  );
                }}
              >
                {i18n.t("ng.noWorkingUser")}
              </button>
              <button
                type="button"
                className="btn btn-info"
                onClick={async () => {
                  this.props.groupEditorStore.updateProperty(
                    "showModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "workingUserModal",
                    false
                  );
                  this.props.groupEditorStore.updateProperty(
                    "individualModal",
                    false
                  );
                  let lastId = await this.props.groupEditorStore.createNewGroup(true);
                  this.props.groupEditorStore.updateProperty("reason", null);
                  await this.props.navigationStore.openInNewTab(
                    "/groupAds/" + lastId
                  );
                }}
              >
                {i18n.t("ng.workingUser")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    //if (this.props.processStore.finish === true) return this.FinishProcessModal();
    if (
      this.props.processStore.opening === false &&
      this.props.processStore.creatingProcess === true
    )
      return this.NewProcessModal();
    else if (this.props.processStore.opening && this.props.processStore.edit)
      return this.EditTaskReportModal();
    else if (this.props.processStore.opening && this.props.processStore.edit2)
      return this.EditGeneralReportModal();
    else if (this.props.processStore.opening && this.props.processStore.edit3)
      return this.ReportsModalForGA();
    else if (this.props.processStore.opening && this.props.processStore.edit4)
      return this.ReportsModalForGATasks();
    else if (this.props.processStore.opening) return this.EditProcessModal();
    else if (
      this.props.groupAdsStore.openModal &&
      this.props.groupAdsStore.editingN
    )
      return this.gaModal();
    else if (
      this.props.groupAdsStore.openModal &&
      this.props.groupAdsStore.editNotes
    )
      return this.NotesEditModalForGA();
    else if (
      this.props.groupAdsStore.openeningModal &&
      this.props.groupAdsStore.showModal
    )
      return this.NotesModalForGA();
    else if (
      this.props.groupEditorStore.showModal &&
      this.props.groupEditorStore.individualModal &&
      !this.props.groupEditorStore.workingUserModal
    )
      return this.groupEditorModalNewGroupIndividual();
    else if (
      this.props.groupEditorStore.showModal &&
      this.props.groupEditorStore.individualModal &&
      this.props.groupEditorStore.workingUserModal
    )
      return this.groupEditorModalWorkingUserIndividual();
    else if (
      this.props.groupEditorStore.showModal &&
      !this.props.groupEditorStore.individualModal &&
      !this.props.groupEditorStore.workingUserModal
    )
      return this.groupEditorModalNewGroup();
    else if (
      this.props.groupEditorStore.showModal &&
      !this.props.groupEditorStore.individualModal &&
      this.props.groupEditorStore.workingUserModal
    )
      return this.groupEditorModalWorkingUser();
    //else if (this.props.groupAdsStore.openModal && this.props.groupAdsStore.addState) return this.addStateModal();
    else return this.FinishProcessModal();
  }
}

export default withTranslation()(MyModal);
