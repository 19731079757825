import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from "react-i18next";
import MyInput from "../basicUI/MyInput";

@inject("newClientStore", "sessionStore", "navigationStore")
@observer
class NewClient extends Component {
    componentDidMount() {
        try {
            let { t } = this.props;
            document.title = t("newClient.title");
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <main role="main" className="container-fluid newClient-page">
                    <div className="row newClient" style={{ padding: "0.5rem" }}>
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="row newClient">
                                <h4>{t("navBar.newClient")}</h4>
                            </div>
                            <div className="row newClient">
                                <div className="col-md-6">

                                    {/*Name*/}
                                    <div className="row newClient" style={{ padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                                        <MyInput
                                            id="clientName"
                                            label={t("newClient.form.clientName") + ":"}
                                            className="form-control-newClient"
                                            name="clientName"
                                            value={this.props.newClientStore.newClientForm.clientName}
                                            onChange={(key, value) => {
                                                this.props.newClientStore.updateFormProperty(key, value);
                                            }}
                                            placeholder={t("newClient.placeholders.clientName")}
                                        ></MyInput>

                                    </div>
                                    {/*Region*/}
                                    <div className="row newClient" style={{ padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                                        <MyInput
                                            id="regionName"
                                            label={t("newClient.form.regionName") + ":"}
                                            className="form-control-newClient"
                                            name="regionName"
                                            value={this.props.newClientStore.newClientForm.regionName}
                                            onChange={(key, value) => {
                                                this.props.newClientStore.updateFormProperty(key, value);
                                            }}
                                            placeholder={t("newClient.placeholders.regionName")}
                                        ></MyInput>
                                    </div>
                                    {/*Adm*/}
                                    <div className="row newClient" style={{ padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                                        <MyInput
                                            id="adm"
                                            label={t("newClient.form.adm") + ":"}
                                            className="form-control-newClient"
                                            name="adm"
                                            value={this.props.newClientStore.newClientForm.adm}
                                            onChange={(key, value) => {
                                                this.props.newClientStore.updateFormProperty(key, value);
                                            }}
                                            placeholder={t("newClient.placeholders.adm")}
                                        ></MyInput>
                                    </div>
                                    {/*Code*/}
                                    <div className="row newClient" style={{ padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                                        <MyInput
                                            id="code"
                                            label={t("newClient.form.code") + ":"}
                                            className="form-control-newClient"
                                            name="code"
                                            value={this.props.newClientStore.newClientForm.code}
                                            onChange={(key, value) => {
                                                this.props.newClientStore.updateFormProperty(key, value);
                                            }}
                                            placeholder={t("newClient.placeholders.code")}
                                        ></MyInput>
                                    </div>
                                    {/*Platforms*/}
                                    <div className="row newClient" style={{ padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                                        <MyInput
                                            id="platforms"
                                            valid="true"
                                            type="text"
                                            label={t("newClient.form.platforms") + ":"}
                                            className="form-control-newClient"
                                            name="platforms"
                                            value={
                                                this.props.newClientStore
                                                    .newClientForm.platforms
                                            }
                                            onChange={(key, value) => {
                                                this.props.newClientStore.updateFormProperty(key, value);
                                            }}
                                            placeholder={t(
                                                "newClient.placeholders.platforms"
                                            )}
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                        <div className="invalid-feedback">
                                            {t("newClient.placeholders.platforms")}
                                        </div>
                                    </div>
                                    {/*Constraints*/}
                                    <div className="row newClient" style={{ padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                                        <MyInput
                                            id="constraints"
                                            valid="true"
                                            type="text"
                                            label={t("newClient.form.constraints") + ":"}
                                            className="form-control-newClient"
                                            name="constraints"
                                            value={
                                                this.props.newClientStore
                                                    .newClientForm.constraints
                                            }
                                            onChange={(key, value) => {
                                                this.props.newClientStore.updateFormProperty(key, value);
                                            }}
                                            placeholder={t(
                                                "newClient.placeholders.constraints"
                                            )}
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                        <div className="invalid-feedback">
                                            {t("newClient.placeholders.platforms")}
                                        </div>

                                    </div>
                                    <div className="row newClient" style={{ padding: "1rem" }}>
                                        {/*Save Button*/}
                                        <MyButton
                                            type="button"
                                            text={t("newClient.buttons.save")}
                                            className="btn btn-info btn-save-newClient"
                                            disabled={
                                                String(this.props.newClientStore.written && this.props.newClientStore.visibleFilter === "complianceStatus")
                                            }
                                            onClick={() => {
                                                //Guarda array platforms
                                                this.props.newClientStore.saveData();
                                            }}
                                        >
                                        </MyButton>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/*Ad States*/}
                                    <div className="row newClient" style={{ padding: "1rem" }}>
                                        <p style={{ paddingRight: "1rem" }}>{t("newClient.form.adStates")}: </p>
                                        <MyButton
                                            id="button-adStates"
                                            type="button"
                                            className="btn adStates-btn"
                                            text={t("newClient.buttons.add")}
                                            style={{
                                                border: "1px solid",
                                            }}
                                            onClick={() =>
                                                this.props.newClientStore.updateVisibleFilter("adStates")
                                            }
                                        ></MyButton>
                                    </div>
                                    <div style={{ padding: "1rem" }}>
                                        {this.props.newClientStore.newClientForm.adStates.map((adstate, j) => {
                                            return (
                                                <div className="row" style={{ margin: "0.5rem" }}>
                                                    <MyButton
                                                        id="button-editStates"
                                                        type="button"
                                                        className="btn adStates-btn"
                                                        text={t("newClient.buttons.edit")}
                                                        style={{
                                                            border: "1px solid",
                                                        }}
                                                        onClick={() => {
                                                            this.props.newClientStore.editStates = !this.props.newClientStore.editStates;
                                                            this.props.newClientStore.loadState(j);
                                                            this.props.newClientStore.updateVisibleFilter("adStates");
                                                        }}
                                                    ></MyButton>
                                                    <p style={{ marginLeft: "0.5rem" }}>{t("newClient.adSForm.name") + ": " + adstate.name + " "}
                                                        {t("newClient.adSForm.ab") + ": " + adstate.abbreviation}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div
                                        id="adStates-popup"
                                        className={"card adStates-popup" + (this.props.newClientStore.visibleFilter === "adStates"
                                            ? " display-block"
                                            : " display-none")
                                        }
                                    >
                                        <h5>{t("newClient.form.adStates")}</h5>
                                        <div className="row adSName" style={{ padding: "1rem" }}>
                                            <p style={{ paddingRight: "1rem" }}>{t("newClient.adSForm.name")}: </p>
                                            <MyInput
                                                id="adSName"
                                                visible="false"
                                                label="adSName"
                                                className="form-control"
                                                name="name"
                                                value={this.props.newClientStore.adStatesForm.name}
                                                onChange={(key, value) => {
                                                    this.props.newClientStore.updateAdSFormProperty(key, value);
                                                }}
                                                placeholder={t("newClient.placeholders.adSName")}
                                            ></MyInput>
                                        </div>
                                        <div className="row adSAb" style={{ padding: "1rem" }}>
                                            <p style={{ paddingRight: "1rem" }}>{t("newClient.adSForm.ab")}: </p>
                                            <MyInput
                                                id="adSAb"
                                                visible="false"
                                                label="adSAb"
                                                className="form-control"
                                                name="abbreviation"
                                                value={this.props.newClientStore.adStatesForm.abbreviation}
                                                onChange={(key, value) => {
                                                    this.props.newClientStore.updateAdSFormProperty(key, value);
                                                }}
                                                placeholder={t("newClient.placeholders.adSAb")}
                                            ></MyInput>
                                        </div>
                                        <div className="row adSValues" style={{ padding: "1rem" }}>
                                            <p style={{ paddingRight: "1rem" }}>{t("newClient.adSForm.values")}: </p>
                                            <MyInput
                                                id="adSValues"
                                                visible="false"
                                                valid="true"
                                                type="text"
                                                className="form-control"
                                                name="values"
                                                value={this.props.newClientStore.adStatesForm.values}
                                                onChange={(key, value) => {
                                                    this.props.newClientStore.updateAdSFormProperty(key, value);
                                                }}
                                                placeholder={t("newClient.placeholders.adSValues")}
                                            ></MyInput>
                                        </div>
                                        <MyButton
                                            id="button-submit"
                                            type="button"
                                            className="btn submit-btn"
                                            text={t("newClient.buttons.cancel")}
                                            style={{
                                                border: "1px solid",
                                                margin: "0.2rem"
                                            }}
                                            onClick={() => {
                                                this.props.newClientStore.clearState();
                                                this.props.newClientStore.updateVisibleFilter("adStates");
                                            }}
                                        ></MyButton>
                                        <MyButton
                                            id="button-submit"
                                            type={t("newClient.buttons.confirm")}
                                            className="btn submit-btn"
                                            text="Confirma"
                                            style={{
                                                border: "1px solid",
                                                margin: "0.2rem"
                                            }}
                                            onClick={() => {
                                                if (this.props.newClientStore.editStates) {
                                                    this.props.newClientStore.editAdState(this.props.newClientStore.adStatesForm.editVal);
                                                }
                                                else this.props.newClientStore.addState();
                                                this.props.newClientStore.updateVisibleFilter("adStates");
                                            }}
                                        ></MyButton>
                                    </div>
                                    {/*Compliance Status*/}
                                    <div className="row newClient" style={{ padding: "1rem" }}>
                                        <p style={{ paddingRight: "1rem" }}>{t("newClient.form.complianceStatus")}: </p>
                                        <MyButton
                                            id="button-complianceStatus"
                                            type="button"
                                            className="btn complianceStatus-btn"
                                            disabled={String(!this.props.newClientStore.written)}
                                            text={t("newClient.buttons.add")}
                                            style={{
                                                border: "1px solid",
                                            }}
                                            onClick={() =>
                                                this.props.newClientStore.updateVisibleFilter("complianceStatus")
                                            }
                                        ></MyButton>
                                    </div>
                                    {this.props.newClientStore.newClientForm.complianceStatus.map((compliance, j) => {
                                        let fColor = compliance.fillColor
                                        let sColor = compliance.strokeColor
                                        return (
                                            <div className="row" style={{ margin: "0.5rem" }}>
                                                <MyButton
                                                    id="button-editCompliance"
                                                    type="button"
                                                    className="btn editCompliance-btn"
                                                    text={t("newClient.buttons.edit")}
                                                    style={{
                                                        border: "1px solid",
                                                    }}
                                                    onClick={() => {
                                                        this.props.newClientStore.editCompliance = !this.props.newClientStore.editCompliance;
                                                        this.props.newClientStore.loadCompliance(j);
                                                        this.props.newClientStore.updateVisibleFilter("complianceStatus");
                                                    }}
                                                ></MyButton>
                                                <label style={{ marginLeft: "0.5rem" }}>{t("newClient.compSForm.key") + ": " + compliance.key + " "}
                                                    {t("newClient.compSForm.fillColor") + ": "}</label>
                                                <label style={{ color: fColor, marginLeft: "0.2rem" }}>{compliance.fillColor}</label>
                                                <label style={{ marginLeft: "0.2rem" }}>{t("newClient.compSForm.strokeColor") + ": "}</label>
                                                <label style={{ color: sColor, marginLeft: "0.2rem" }}>{compliance.strokeColor}</label>
                                            </div>
                                        )
                                    })}
                                    {/*Popup de Compliance Status*/}
                                    <div
                                        id="complianceStatus-popup"
                                        className={"card complianceStatus-popup" + (this.props.newClientStore.visibleFilter === "complianceStatus"
                                            ? " display-block"
                                            : " display-none")
                                        }
                                    >
                                        <h5>{t("newClient.form.complianceStatus")}</h5>
                                        <div className="row compSKey" style={{ padding: "1rem" }}>
                                            <p style={{ paddingRight: "1rem" }}>{t("newClient.compSForm.key")}: </p>
                                            <MyInput
                                                id="compSKey"
                                                visible="false"
                                                label="compSKey"
                                                className="form-control"
                                                name="key"
                                                value={this.props.newClientStore.complianceStatusForm.key}
                                                onChange={(key, value) => {
                                                    this.props.newClientStore.updateCompSFormProperty(key, value);
                                                }}
                                                placeholder={t("newClient.placeholders.compSKey")}
                                            ></MyInput>
                                        </div>
                                        <div className="row compSFill" style={{ padding: "1rem" }}>
                                            <p style={{ paddingRight: "1rem" }}>{t("newClient.compSForm.fillColor")}: </p>
                                            <MyInput
                                                id="compSFill"
                                                visible="false"
                                                label="compSKey"
                                                className="form-control"
                                                name="fillColor"
                                                value={this.props.newClientStore.complianceStatusForm.fillColor}
                                                onChange={(key, value) => {
                                                    this.props.newClientStore.updateCompSFormProperty(key, value);
                                                }}
                                                placeholder={t("newClient.placeholders.compSFill")}
                                            ></MyInput>
                                        </div>
                                        <div className="row compStroke" style={{ padding: "1rem" }}>
                                            <p style={{ paddingRight: "1rem" }}>{t("newClient.compSForm.strokeColor")}: </p>
                                            <MyInput
                                                id="compStroke"
                                                visible="false"
                                                label="compStroke"
                                                className="form-control"
                                                name="strokeColor"
                                                value={this.props.newClientStore.complianceStatusForm.strokeColor}
                                                onChange={(key, value) => {
                                                    this.props.newClientStore.updateCompSFormProperty(key, value);
                                                }}
                                                placeholder={t("newClient.placeholders.compStroke")}
                                            ></MyInput>
                                        </div>
                                        <div style={{ padding: "1rem" }}>
                                            {this.props.newClientStore.newClientForm.arrayConstraints.map((constraint, j) => {
                                                //console.log(this.props.newClientStore.newClientForm.arrayConstraints, constraint)
                                                //console.log("A veure el compSF: ", this.props.newClientStore.complianceStatusForm.constraints);
                                                //console.log("A veure la j: ", j);

                                            return (
                                                    <div className="input" style={{ paddingRight: "0.5rem" }}>
                                                        <input
                                                            id="constraint"
                                                            type="checkbox"
                                                            className="checkbox"
                                                            checked={this.props.newClientStore.complianceStatusForm.constraints[j].value}
                                                            onChange={() => {
                                                                this.props.newClientStore.updateCheckConstraint(j);
                                                            }}
                                                        ></input>
                                                        <p>{constraint}</p>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                        <MyButton
                                            id="button-submit"
                                            type="button"
                                            className="btn submit-btn"
                                            text={t("newClient.buttons.cancel")}
                                            style={{
                                                border: "1px solid",
                                                margin: "0.2rem"
                                            }}
                                            onClick={() => {
                                                this.props.newClientStore.clearCompliance();
                                                this.props.newClientStore.updateVisibleFilter("complianceStatus");
                                            }}
                                        ></MyButton>
                                        <MyButton
                                            id="button-submit"
                                            type="button"
                                            className="btn submit-btn"
                                            text={t("newClient.buttons.confirm")}
                                            style={{
                                                border: "1px solid",
                                                margin: "0.2rem"
                                            }}
                                            onClick={() => {
                                                if (this.props.newClientStore.editCompliance) {
                                                    this.props.newClientStore.editComplianceStatus(this.props.newClientStore.complianceStatusForm.editVal);
                                                }
                                                else this.props.newClientStore.addCompliance();
                                                console.log("Constraints després d'editar: ", this.props.newClientStore.newClientForm.complianceStatus);
                                                this.props.newClientStore.updateVisibleFilter("complianceStatus");
                                            }}
                                        ></MyButton>
                                    </div>
                                    {/*Bools*/}
                                    <div className="row newClient" style={{ paddingLeft: "1rem" }}>
                                        <div className="input" style={{ paddingRight: "0.5rem" }}>
                                            <input
                                                id="mandatoryLicense"
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => {
                                                    this.props.newClientStore.checkBox("mandatoryLicense");
                                                }}
                                            ></input>
                                        </div>
                                        <p>{t("newClient.bools.mandatoryLicense")}</p>
                                    </div>
                                    <div className="row newClient" style={{ paddingLeft: "1rem" }}>
                                        <div className="input" style={{ paddingRight: "0.5rem" }}>
                                            <input
                                                id="allowedAccess"
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => {
                                                    this.props.newClientStore.checkBox("allowedAccess");
                                                }}
                                            ></input>
                                        </div>
                                        <p>{t("newClient.bools.allowedAccess")}</p>
                                    </div>
                                    <div className="row newClient" style={{ paddingLeft: "1rem" }}>
                                        <div className="input" style={{ paddingRight: "0.5rem" }}>
                                            <input
                                                id="monthlyMapping"
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => {
                                                    this.props.newClientStore.checkBox("monthlyMapping");
                                                }}
                                            ></input>
                                        </div>
                                        <p>{t("newClient.bools.monthlyMapping")}</p>
                                    </div>
                                    <div className="row newClient" style={{ paddingLeft: "1rem" }}>
                                        <div className="input" style={{ paddingRight: "0.5rem" }}>
                                            <input
                                                id="compliance"
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => {
                                                    this.props.newClientStore.checkBox("compliance");
                                                }}
                                            ></input>
                                        </div>
                                        <p>{t("newClient.bools.compliance")}</p>
                                    </div>
                                    <div className="row newClient" style={{ paddingLeft: "1rem" }}>
                                        <div className="input" style={{ paddingRight: "0.5rem" }}>
                                            <input
                                                id="dashboard"
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => {
                                                    this.props.newClientStore.checkBox("dashboard");
                                                }}
                                            ></input>
                                        </div>
                                        <p>{t("newClient.bools.dashboard")}</p>
                                    </div>
                                    <div className="row newClient" style={{ paddingLeft: "1rem" }}>
                                        <div className="input" style={{ paddingRight: "0.5rem" }}>
                                            <input
                                                id="censusMap"
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => {
                                                    this.props.newClientStore.checkBox("censusMap");
                                                }}
                                            ></input>
                                        </div>
                                        <p>{t("newClient.bools.censusMap")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row newClient" style={{ padding: "1rem" }}>
                                {/*Submit Button*/}
                                <MyButton
                                    type="button"
                                    text={t("newClient.buttons.submit")}
                                    className="btn btn-info btn-submit-newClient"
                                    onClick={() => {
                                        this.props.newClientStore.createClient();
                                        let boxes = document.querySelectorAll(".checkbox");
                                        for (let i = 0; i < boxes.length; i++) {
                                            boxes[i].checked = false;
                                        }
                                    }}
                                >
                                </MyButton>
                            </div>
                            {/*Load file button*/}
                            <div className="row newClient" style={{ padding: "1rem" }}>
                                <input 
                                    type="file"
                                    id="load-file input 0"
                                    label="Choose a file: "
                                >                                    
                                </input>
                                <MyButton
                                    type="button"
                                    text="Test"
                                    className="btn"
                                    onClick={() => {
                                        let f = document.getElementById("load-file input 0").files[0];
                                        console.log(f);
                                        console.log(f.name, f.size);
                                    }}
                                ></MyButton>
                            </div>
                            {/*Load file según una guía a ver qué tal */}
                            <form encType="multipart/form-data" method="POST" id="load-form">
                                <input type="file" id="load-file" name="file">
                                </input>
                                <MyButton type="submit" text="Hola" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.newClientStore.uploadFile();
                                }}>
                                    </MyButton>
                            </form>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </main>
            </>
        )
    }
}
export default withTranslation()(NewClient);