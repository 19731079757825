import { observable, computed, action } from "mobx";
import i18n from "../../i18n";

class CommandStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    @observable initialized = false;
    @observable cityHallData = null;
    @observable cityName;
    @observable cityHallDataLast = null;
    @observable cityId;
    @observable cityAdm;
    @observable errorGroupAdsCount;
    @observable month;
    @observable dateInfo = "03-2024";
    @observable historic = null;
    @observable historicData = null;
    @observable position = 0;
    @observable uniqueMonth = true;
    @observable monthStart = 0;
    @observable monthEnd = 0;
    @observable firstSaveGrouped = false
    @observable typeSelector = {
        fromOption: "all",
        toOption: "-1",
        value: "0",
        url: "",
        selectOptions: [
            { key: "all", value: i18n.t("roomType.all") },
            { key: "house", value: i18n.t("roomType.house") },
            { key: "rural", value: i18n.t("roomType.rural") },
            { key: "private", value: i18n.t("roomType.private") },
            { key: "shared", value: i18n.t("roomType.shared") },
            { key: "apartment", value: i18n.t("roomType.apartment") },
            { key: "hotel", value: i18n.t("roomType.hotel") },
            { key: "bib", value: i18n.t("roomType.bib") },
            { key: "pension", value: i18n.t("roomType.pension") },
            { key: "lodge", value: i18n.t("roomType.lodge") },
            { key: "camping", value: i18n.t("roomType.camping") },
            { key: "ship", value: i18n.t("roomType.ship") },
        ],
    };

    @observable startDate = {
        fromOption: "-1",
        toOption: "-1",
        value: "0",
        url: "",
        selectOptions: [
            { value: i18n.t("months.january")+"  "+new Date().getFullYear(), key: 0 },
            { value: i18n.t("months.february")+"  "+new Date().getFullYear(), key: 1 },
            { value: i18n.t("months.march")+"  "+new Date().getFullYear(), key: 2 },
            { value: i18n.t("months.april")+"  "+new Date().getFullYear(), key: 3 },
            { value: i18n.t("months.may")+"  "+new Date().getFullYear(), key: 4 },
            { value: i18n.t("months.june")+"  "+new Date().getFullYear(), key: 5 },
            { value: i18n.t("months.july")+"  "+new Date().getFullYear(), key: 6 },
            { value: i18n.t("months.august")+"  "+new Date().getFullYear(), key: 7 },
            { value: i18n.t("months.september")+"  "+new Date().getFullYear(), key: 8 },
            { value: i18n.t("months.october")+"  "+new Date().getFullYear(), key: 9 },
            { value: i18n.t("months.november")+"  "+new Date().getFullYear(), key: 10 },
            { value: i18n.t("months.december")+"  "+new Date().getFullYear(), key: 11 },
            { value: i18n.t("months.january")+"  "+(new Date().getFullYear()-1), key: 12 },
            { value: i18n.t("months.february")+"  "+(new Date().getFullYear()-1), key: 13 },
            { value: i18n.t("months.march")+"  "+(new Date().getFullYear()-1), key: 14 },
            { value: i18n.t("months.april")+"  "+(new Date().getFullYear()-1), key: 15 },
            { value: i18n.t("months.may")+"  "+(new Date().getFullYear()-1), key: 16 },
            { value: i18n.t("months.june")+"  "+(new Date().getFullYear()-1), key: 17 },
            { value: i18n.t("months.july")+"  "+(new Date().getFullYear()-1), key: 18 },
            { value: i18n.t("months.august")+"  "+(new Date().getFullYear()-1), key: 19 },
            { value: i18n.t("months.september")+"  "+(new Date().getFullYear()-1), key: 20 },
            { value: i18n.t("months.october")+"  "+(new Date().getFullYear()-1), key: 21 },
            { value: i18n.t("months.november")+"  "+(new Date().getFullYear()-1), key: 22 },
            { value: i18n.t("months.december")+"  "+(new Date().getFullYear()-1), key: 23 },
        ],
    };

    @observable endDate = {
        fromOption: "-1",
        toOption: "-1",
        value: "0",
        url: "",
        selectOptions: [
            { value: i18n.t("months.january")+"  "+new Date().getFullYear(), key: 0 },
            { value: i18n.t("months.february")+"  "+new Date().getFullYear(), key: 1 },
            { value: i18n.t("months.march")+"  "+new Date().getFullYear(), key: 2 },
            { value: i18n.t("months.april")+"  "+new Date().getFullYear(), key: 3 },
            { value: i18n.t("months.may")+"  "+new Date().getFullYear(), key: 4 },
            { value: i18n.t("months.june")+"  "+new Date().getFullYear(), key: 5 },
            { value: i18n.t("months.july")+"  "+new Date().getFullYear(), key: 6 },
            { value: i18n.t("months.august")+"  "+new Date().getFullYear(), key: 7 },
            { value: i18n.t("months.september")+"  "+new Date().getFullYear(), key: 8 },
            { value: i18n.t("months.october")+"  "+new Date().getFullYear(), key: 9 },
            { value: i18n.t("months.november")+"  "+new Date().getFullYear(), key: 10 },
            { value: i18n.t("months.december")+"  "+new Date().getFullYear(), key: 11 },
            { value: i18n.t("months.january")+"  "+(new Date().getFullYear()-1), key: 12 },
            { value: i18n.t("months.february")+"  "+(new Date().getFullYear()-1), key: 13 },
            { value: i18n.t("months.march")+"  "+(new Date().getFullYear()-1), key: 14 },
            { value: i18n.t("months.april")+"  "+(new Date().getFullYear()-1), key: 15 },
            { value: i18n.t("months.may")+"  "+(new Date().getFullYear()-1), key: 16 },
            { value: i18n.t("months.june")+"  "+(new Date().getFullYear()-1), key: 17 },
            { value: i18n.t("months.july")+"  "+(new Date().getFullYear()-1), key: 18 },
            { value: i18n.t("months.august")+"  "+(new Date().getFullYear()-1), key: 19 },
            { value: i18n.t("months.september")+"  "+(new Date().getFullYear()-1), key: 20 },
            { value: i18n.t("months.october")+"  "+(new Date().getFullYear()-1), key: 21 },
            { value: i18n.t("months.november")+"  "+(new Date().getFullYear()-1), key: 22 },
            { value: i18n.t("months.december")+"  "+(new Date().getFullYear()-1), key: 23 },
        ],
    };

    @action("create date options for the selectors") createOptionsDate = () =>{
        let month = (new Date().getMonth()) - 1
        let surplus = 0
        if (month === -1){
            surplus = 1
            month = 11
        }
        let options = [];
        let year = 0;
        let monthName = [i18n.t("months.january"),i18n.t("months.february"),i18n.t("months.march"),i18n.t("months.april"),i18n.t("months.may"),i18n.t("months.june"),i18n.t("months.july"),i18n.t("months.august"),i18n.t("months.september"),i18n.t("months.october"),i18n.t("months.november"),i18n.t("months.december")]
        while(options.length < 24){
            if (month < 0) {
                month = 11;
                year += 1;
            }
            let value = monthName[month] + "  " + (new Date().getFullYear()-year-surplus)
            options.push({value: value, key: month + (year*12)})
            month -= 1
        }
        console.log(options)
        this.endDate.selectOptions = options;
        this.startDate.selectOptions = options;
        this.startDate.fromOption = String(this.startDate.selectOptions[0].key)
        this.endDate.fromOption = String(this.endDate.selectOptions[0].key)
        this.position = this.startDate.selectOptions[0].key
    }

    @action("return month") returnAvailableMonth = async (
        data,
        month,
        cont
    ) => {
        //Si hay datos del mes, devuelve el mes
        if (data[month] && Object.keys(data[month]).length > 0) return month;
        else {
            //Si el contador es 12 ya ha pasado por todo los meses (Del 0 al 11) y por lo tanto que devuelva error
            if (cont === 12) {
                return null;
            }
            //Si el mes es 0, vuelve al 11 y sino se resta uno. Falta volver a llamar a la funcion con parametros correctos
            else {
                let myMonth = month === 0 ? 11 : month - 1;
                return this.returnAvailableMonth(data, myMonth, cont + 1);
            }
        }
    };

    @action("update attribute") updateProperty = (attrName, value) => {
        //console.log("UpdateProperty Start",attrName,this[attrName],value)
        this[attrName] = value;
        //console.log("UpdateProperty End",attrName,this[attrName],value)
    };

    @action("update nested attribute") updateNestedProperty = (
        attrPath,
        value
    ) => {
        console.log(attrPath, value);
        let path = attrPath.split(".");
        this[path[0]][path[1]] = value;
        console.log(this[path[0]][path[1]]);
    };

    @action("save current changes") initiateStore = async () => {
        this.createOptionsDate();
        let search = this.rootStore.navigationStore.history.location.search;
        if (!!search || search === "") {
            if (search[0] === "?") {
                search = search.substr(1);
                let elemParams = search.split("=");
                if (elemParams[0] === "region") {
                    let city =
                        await this.rootStore.requestStore.getCityHallData(
                            elemParams[1]
                        );
                    let cityname =
                        await this.rootStore.requestStore.getCityHallName(
                            elemParams[1]
                        );
                    console.log(city, cityname);
                    this.cityId = city._id;
                    this.cityAdm = cityname.adm;
                    this.cityName = cityname.name;
                    if (city.dateInfo !== undefined)
                        this.dateInfo = city.dateInfo;
                    console.log(this.dateInfo, city.dateInfo)
                    //let month = (new Date().getMonth()) % 12;
                    let historicMonth = new Date().getMonth() % 12;
                    //this.startDate.fromOption = String(historicMonth);
                    //this.endDate.fromOption = String(historicMonth);
                    this.startDate.value = historicMonth;
                    this.endDate.value = historicMonth;
                    //this.position = historicMonth;
                    this.historic = city.historicCityHall;
                    console.log(this.historic)
                    this.historicData =
                        this.historic[Math.floor(this.position / 12)][
                            this.position % 12
                        ].all;
                    let month = (new Date().getMonth() + 1) % 12;
                    let myMonth = await this.returnAvailableMonth(
                        city.city_hall.dataHistory,
                        month,
                        0
                    );
                    if (myMonth !== null) {
                        console.log(myMonth)
                        this.cityHallData = city.city_hall.dataHistory[myMonth];
                        this.month = myMonth;
                        // if (city.city_hall.dataHistory[month] && Object.keys(city.city_hall.dataHistory[month]).length > 0) this.cityHallData = city.city_hall.dataHistory[month];
                        // else this.cityHallData = city.city_hall.dataHistory[month-1];
                        let pastMonth = null;

                        if (month !== 0)
                            pastMonth = await this.returnAvailableMonth(
                                city.city_hall.dataHistory,
                                month - 1,
                                0
                            );
                        else
                            pastMonth = await this.returnAvailableMonth(
                                city.city_hall.dataHistory,
                                11,
                                0
                            );

                        if (pastMonth !== null)
                            this.cityHallDataLast =
                                city.city_hall.dataHistory[pastMonth];
                                this.initialized = true;
                        let groupAdsError =
                            await this.rootStore.requestStore.getGroupAdsErrorCount(
                                city._id
                            );
                        // console.log(groupAdsError);
                        this.errorGroupAdsCount = groupAdsError.count;
                        // console.log(this.errorGroupAdsCount);
                    }
                }
            } else {
                
                let userData = (
                    await this.rootStore.requestStore.getUserAssignedRegions(
                        this.rootStore.userStore.getUser().id
                    )
                ).data;
                this.rootStore.navigationStore.replace(
                    "/command?region=" + userData.assignedRegions[0]
                );
                this.rootStore.navigationStore.reload();
            }
        }
        this.initialized = true;
    };

    @action("go to filter") tableToWA = (row, column) => {
        if (row !== "num_users") {
            if (row === "total_ads") {
                if (column !== "total")
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&platf=" +
                            column[0].toUpperCase() +
                            column.substring(1)
                    );
                else
                    this.rootStore.navigationStore.openInNewTab(
                        "/area?focusedRegion=" + this.cityId
                    );
            } else {
                if (column !== "total")
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&cityhall=" +
                            row +
                            "&platf=" +
                            column[0].toUpperCase() +
                            column.substring(1)
                    );
                else
                    this.rootStore.navigationStore.openInNewTab(
                        "/area?focusedRegion=" +
                            this.cityId +
                            "&cityhall=" +
                            row
                    );
            }
        }
    };

    @action("Change city") changeCity = async (id) => {
        let city = await this.rootStore.requestStore.getCityHallData(id);
        this.rootStore.navigationStore.replace("/command?region=" + city._id);
        this.rootStore.navigationStore.reload();
    };

    @action("Download Excel") downloadExcel = async (
        userName,
        platform,
        license
    ) => {
        console.log("DOING DOWNLOADING EXCEL FUNC");
        let ans;
        if (this.month !== null) {
            let month =this.month;
            //if (this.month === 0) month = 12;
            //else month = this.month - 1;

            if (month < 10) month = "0" + month;
            else month = "" + month;

            console.log(platform, license)
            if (platform === "all")
                ans = await this.rootStore.requestStore.getAdsForExcel(
                    { _id: this.cityId, adm: this.cityAdm },
                    "error=0&cityhall=" +
                        license +
                        "&workingUser=NoFilter&favAds=NoFilter&minNights=" +
                        month +
                        "$31"
                );
            else
                ans = await this.rootStore.requestStore.getAdsForExcel(
                    { _id: this.cityId, adm: this.cityAdm },
                    "error=0&cityhall=" +
                        license +
                        "&workingUser=NoFilter&favAds=NoFilter&minNights=" +
                        month +
                        "$31&platf=" +
                        platform
                );
            //let ads = JSON.parse(JSON.stringify(this.totalUrlAds));
            console.log(ans);

            let auxAds = [];
            if (ans && ans.data && ans.data.ads) {
                console.log(ans.data.ads);
                let ads = ans.data.ads;

                for (let i = 0; i < ads.length; i++) {
                    // let municipality = await this.generateVal(ads[i], "municipality")
                    // let county = await this.generateVal(ads[i], "county")
                    // let title = await this.generateVal(ads[i], "title")
                    // let commercialType = await this.generateVal(ads[i], "commercialType")
                    // let transactionType = await this.generateVal(ads[i], "transactionType")
                    // let price = await this.generateVal(ads[i], "price")
                    // let surface = await this.generateVal(ads[i], "surface")
                    //plataforma, id anunci (sense plataforma), url, llicència
                    // if (municipality &&  county && title && commercialType && transactionType && price && surface) {
                    let platform = "Plataforma";
                    let id = "ID anunci";
                    let url = "URL";
                    let license = "Llicencia";
                    let val = {};

                    val[platform] = ads[i].id.slice(0, 6);
                    val[id] = ads[i].id.slice(6);
                    val[url] = ads[i].url;
                    val[license] = ads[i].licCode;

                    auxAds.push(val);
                    //}
                }
            }
            // console.log(auxAds)
            if (auxAds.length > 0) {
                if (auxAds.length > 15000) {
                    window.alert("excelTooMuch");
                    //await this.rootStore.requestStore.generateExcel(userName, auxAds)
                } else {
                    console.log("GOING TO GENERATE THE EXCEL");
                    await this.rootStore.requestStore.generateExcel(
                        userName,
                        auxAds
                    );
                    // window.alert(i18n.t("polygon.searches.excelCreated"))
                }
            } else {
                //Poner el caso de que demasiados anuncios
                window.alert("noExcelCreated");
            }
        } else {
            //Poner el caso de que demasiados anuncios
            window.alert("noExcelCreated");
        }
    };

    @action("Save the month variables to use on the get Tabledata function") saveMonths = () => {
        if (this.uniqueMonth){
            this.position = parseInt(this.startDate.fromOption)
        }
        else {
            this.monthStart = parseInt(this.startDate.fromOption)
            this.monthEnd = parseInt(this.endDate.fromOption)
        }
        this.firstSaveGrouped = true
    }

    @action("update Unique month boolean") changeMonthUniqueness = (
        attrKey,
        attrName,
        value
    ) => {
        this.uniqueMonth = !value;
    };

    @action("update selector for historicData") updateTypeSelector = (
        attrName,
        value
    ) => {
        this.updateNestedProperty(attrName, value);
        //this.updateHistoricData();
    };

    @action("update postion") changePosition = (pos) => {
        if (pos === "right"){
            let month = new Date().getMonth()
            if((this.position+1)%12 === 0){
                this.position -= 23;
            }
            else this.position += 1
            this.startDate.fromOption = String(this.position)
            
        }
        else {
            if((this.position)%12 === 0 ){
                this.position += 23
            }
            else this.position -= 1
            this.startDate.fromOption = String(this.position)
        }
        console.log(this.position, pos)
    }

    @computed get tableData() {
        let data = {};
        if (this.historic !== undefined && this.historic !== null)
            if (this.uniqueMonth || !this.firstSaveGrouped) {
                switch (this.typeSelector.fromOption) {
                    case "all":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].all;
                        break;
                    case "house":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].house;
                        break;
                    case "rural":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].rural;
                        break;
                    case "private":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].private;
                        break;
                    case "shared":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].shared;
                        break;
                    case "apartment":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].apartment;
                        break;
                    case "bib":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].bib;
                        break;
                    case "pension":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].pension;
                        break;
                    case "camping":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].camping;
                        break;
                    case "ship":
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].ship;
                        break;

                    default:
                        data =
                            this.historic[Math.floor(this.position / 12)][
                                this.position % 12
                            ].all;
                        break;
                }
            } else {
                let intervalStart;
                let intervalEnd;
                
                for(let i = 0; i < this.startDate.selectOptions.length; i = i + 1){
                    if (this.startDate.selectOptions[i].key === this.monthStart) intervalStart = i;
                    if (this.endDate.selectOptions[i].key === this.monthEnd) intervalEnd = i;
                }
                /*if (
                    this.monthStart <=
                    this.monthEnd
                ) {
                    intervalStart = this.monthStart;
                    intervalEnd = this.monthEnd;
                } else {
                    intervalStart = this.monthEnd;
                    intervalEnd = this.monthStart;
                }*/
                data = {
                    total_ads: 0,
                    websites: {
                        Airbnb: 0,
                        Booking: 0,
                        Homeaway: 0,
                        Niumba: 0,
                        Rentalia: 0,
                        Tripadvisor: 0,
                    },
                    licenses: {
                        withLicense: {
                            websites: {
                                Airbnb: 0,
                                Booking: 0,
                                Homeaway: 0,
                                Niumba: 0,
                                Rentalia: 0,
                                Tripadvisor: 0,
                            },
                            count: 0,
                            withAddress: {
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                                count: 0,
                            },
                            official: {
                                count: 0,
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                            },
                            incorrect: {
                                count: 0,
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                            },
                            duplicated: 0,
                        },
                        withErrorLicense: {
                            websites: {
                                Airbnb: 0,
                                Booking: 0,
                                Homeaway: 0,
                                Niumba: 0,
                                Rentalia: 0,
                                Tripadvisor: 0,
                            },
                            count: 0,
                            withAddress: {
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                                count: 0,
                            },
                            exempt: {
                                count: 0,
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                            },
                            noLicense: {
                                count: 0,
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                            },
                            withErrors: {
                                count: 0,
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                            },
                            duplicated: 0,
                            realFraud: {
                                count: 0,
                                websites: {
                                    Airbnb: 0,
                                    Booking: 0,
                                    Homeaway: 0,
                                    Niumba: 0,
                                    Rentalia: 0,
                                    Tripadvisor: 0,
                                },
                            },
                        },
                    },
                };
                let value;
                for (let i = intervalStart; i <= intervalEnd; i++) {
                    let valueMonth = this.startDate.selectOptions[i].key
                    switch (this.typeSelector.fromOption) {
                        case "all":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12].all;
                            break;
                        case "house":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12].house;
                            break;
                        case "rural":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12].rural;
                            break;
                        case "private":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12]
                                    .private;
                            break;
                        case "shared":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12]
                                    .shared;
                            break;
                        case "apartment":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12]
                                    .apartment;
                            break;
                        case "bib":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12].bib;
                            break;
                        case "pension":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12]
                                    .pension;
                            break;
                        case "camping":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12]
                                    .camping;
                            break;
                        case "ship":
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12].ship;
                            break;
                        default:
                            value =
                                this.historic[Math.floor(valueMonth/ 12)][valueMonth% 12].all;
                            break;
                    }
                    if (value === undefined) continue
                    if (value.total_ads !== undefined)
                        data.total_ads += value.total_ads;
                    if (value.websites !== undefined) {
                        for (let web in value.websites) {
                            data.websites[web] += value.websites[web];
                        }
                    }
                    if (value.licenses !== undefined) {
                        if(value.licenses.withLicense !== undefined){
                            if(value.licenses.withLicense.websites !== undefined){
                                for (let web in value.licenses.withLicense.websites)
                                    data.licenses.withLicense.websites[web] += value.licenses.withLicense.websites[web];
                            }
                            data.licenses.withLicense.count += value.licenses.withLicense.count
                            if(value.licenses.withLicense.withAddress !== undefined){
                                if(value.licenses.withLicense.withAddress.websites !== undefined){
                                    for (let web in value.licenses.withLicense.withAddress.websites)
                                        data.licenses.withLicense.withAddress.websites[web] += value.licenses.withLicense.withAddress.websites[web]
                                }
                                data.licenses.withLicense.withAddress.count += value.licenses.withLicense.withAddress.count
                            }
                            if(value.licenses.withLicense.official !== undefined){
                                if(value.licenses.withLicense.official.websites !== undefined){
                                    for (let web in value.licenses.withLicense.official.websites)
                                        data.licenses.withLicense.official.websites[web] += value.licenses.withLicense.official.websites[web]
                                }
                                data.licenses.withLicense.official.count += value.licenses.withLicense.official.count
                            }
                            if(value.licenses.withLicense.incorrect !== undefined){
                                if(value.licenses.withLicense.incorrect.websites !== undefined){
                                    for (let web in value.licenses.withLicense.incorrect.websites)
                                        data.licenses.withLicense.incorrect.websites[web] += value.licenses.withLicense.incorrect.websites[web]
                                }
                                data.licenses.withLicense.incorrect.count += value.licenses.withLicense.incorrect.count
                            }
                            if(value.licenses.withLicense.duplicated !== undefined)
                                data.licenses.withLicense.duplicated += value.licenses.withLicense.duplicated
                        }
                        if(value.licenses.withErrorLicense !== undefined){
                            if(value.licenses.withErrorLicense.websites !== undefined){
                                for (let web in value.licenses.withErrorLicense.websites)
                                    data.licenses.withErrorLicense.websites[web] += value.licenses.withErrorLicense.websites[web];
                            }
                            data.licenses.withErrorLicense.count += value.licenses.withErrorLicense.count
                            if(value.licenses.withErrorLicense.withAddress !== undefined){
                                if(value.licenses.withErrorLicense.withAddress.websites !== undefined){
                                    for (let web in value.licenses.withErrorLicense.withAddress.websites)
                                        data.licenses.withErrorLicense.withAddress.websites[web] += value.licenses.withErrorLicense.withAddress.websites[web]
                                }
                                data.licenses.withErrorLicense.withAddress.count += value.licenses.withErrorLicense.withAddress.count
                            }
                            if(value.licenses.withErrorLicense.exempt !== undefined){
                                if(value.licenses.withErrorLicense.exempt.websites !== undefined){
                                    for (let web in value.licenses.withErrorLicense.exempt.websites)
                                        data.licenses.withErrorLicense.exempt.websites[web] += value.licenses.withErrorLicense.exempt.websites[web]
                                }
                                data.licenses.withErrorLicense.exempt.count += value.licenses.withErrorLicense.exempt.count
                            }
                            if(value.licenses.withErrorLicense.noLicense !== undefined){
                                if(value.licenses.withErrorLicense.noLicense.websites !== undefined){
                                    for (let web in value.licenses.withErrorLicense.noLicense.websites)
                                        data.licenses.withErrorLicense.noLicense.websites[web] += value.licenses.withErrorLicense.noLicense.websites[web]
                                }
                                data.licenses.withErrorLicense.noLicense.count += value.licenses.withErrorLicense.noLicense.count
                            }
                            if(value.licenses.withErrorLicense.withErrors !== undefined){
                                if(value.licenses.withErrorLicense.withErrors.websites !== undefined){
                                    for (let web in value.licenses.withErrorLicense.withErrors.websites)
                                        data.licenses.withErrorLicense.withErrors.websites[web] += value.licenses.withErrorLicense.withErrors.websites[web]
                                }
                                data.licenses.withErrorLicense.withErrors.count += value.licenses.withErrorLicense.withErrors.count
                            }
                            if(value.licenses.withErrorLicense.realFraud !== undefined){
                                if(value.licenses.withErrorLicense.realFraud.websites !== undefined){
                                    for (let web in value.licenses.withErrorLicense.realFraud.websites)
                                        data.licenses.withErrorLicense.realFraud.websites[web] += value.licenses.withErrorLicense.realFraud.websites[web]
                                }
                                data.licenses.withErrorLicense.realFraud.count += value.licenses.withErrorLicense.realFraud.count
                            }
                            if(value.licenses.withErrorLicense.duplicated !== undefined)
                                data.licenses.withErrorLicense.duplicated += value.licenses.withErrorLicense.duplicated
                        }
                    }

                }
            }
        console.log(data)
        //if (data === undefined && !this.uniqueMonth) data = this.historic[Math.floor(this.position / 12)][this.position % 12].all;
        return data;
    }
}

export default CommandStore;
