import { observable, action, computed } from "mobx";

class TeamStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateProperty = this.updateProperty.bind(this);
  }

  @observable dataUsers = [];
  @observable res = null;
  @observable merged = null;
  @observable totalAdsUser = [];
  @computed get tableData() {
    let data = null
    if (this.dataUsers && this.dataUsers.length > 0) {
      data = this.dataUsers.slice(
        this.rootStore.pageStore.len * this.rootStore.pageStore.page,

        this.rootStore.pageStore.len * (this.rootStore.pageStore.page + 1)
      );

      for (let i = 0; i < data.length; i++) {
        for (var key in data[i]) {
          if (data[i].hasOwnProperty(key)) {
            if (!data[i][key]) {
              data[i][key] = "-";
            }
          }
        }
      }
    }
    return data;
  }

  @action("update attribute") updateProperty = (attrName, value) => {
    this[attrName] = value;
  };

  initView = () => {
    this.updateData();
  };

  updateData = async () => {
    try {
      this.rootStore.pageStore.updateProperty(
        "totalPages",
        Math.ceil(this.dataUsers.length / this.rootStore.pageStore.len)
      );
      this.rootStore.pageStore.updateProperty("page", 0);
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  @action("Get users team") getUsers = async () => {
    try {
      let user = await this.rootStore.sessionStore.getSession();
      this.dataUsers = await this.rootStore.requestStore.getUsersFromGroup(
        user.group
      );
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get statistics team") getStatisticsTeam = async (username, target, i) => {
    try {
      if (document.getElementById(username + i).className === "") {
        let aux;
        let result = [];
        this.totalAdsUser = [];
        this.merged = [];
        // for (let i = 0; i < this.dataUsers.length; i++) {
        aux = this.dataUsers[(this.rootStore.pageStore.page*20)+i];
        if (aux.assignedRegions) {
          this.res = await this.rootStore.requestStore.getAdsTeam(
            aux.username,
            aux.assignedRegions
          );
          //console.log("SORTED ISSS ", this.res.slice().sort("regionName"))
          let total = this.res.reduce(function(accum, totalAds) {
            return accum + totalAds.numAds;
          }, 0);
          this.totalAdsUser.push({ username: aux.username, totalAds: total });
          result.push(this.res);
        }
        this.merged = [].concat.apply([], result);
        // }
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };


  @action("Download ads modified by user") downloadAdsModifiedByUser = async (username) => {
    try {  
      console.log(username)
      let data = await this.rootStore.requestStore.getModifiedAdsByUser(username)
      console.log(data)
      let infoExcel = []
      for (let adGroup of data.data){
        console.log(adGroup)
        let group = "Adgroup Code"
        let id = "Ad ID"
        let platform = "Platform"
        let address = "Address"
        let finishedProcessing = "Processed"
        let hostAddress = "Host user"
        let hostName = "Host name"
        let license = "License"
        let groupTitle = "Title"
        let municipality = "Municipality"
        let tags = "Tags"
        let state = "Ad State"
        let stateNotes = "Ad State Notes"
        let workingUser = "Working User"

        for(let ad of adGroup.childrenId){
          console.log(ad)

          let value = {}
          
          value[group] = adGroup._id;
          value[platform] = ad.slice(0,6);
          value[id] = ad.slice(6);
          value[address] = adGroup.address;
          value[finishedProcessing] = adGroup.finishedProcessing;
          if(adGroup.host){
            value[hostAddress] = adGroup.host.address;
            value[hostName] = adGroup.host.name;
          }
          else {
            value[hostAddress] = ""
            value[hostName] = ""
          }
          value[license] = adGroup.license;
          value[groupTitle] = adGroup.title;
          value[municipality] = adGroup.municipality;
          value[tags] = (adGroup.tags).toString();
          if(adGroup.state){
            value[state] = adGroup.state.name;
            if(adGroup.state.message)
            value[stateNotes] = adGroup.state.message.notes
            else value[stateNotes] = ""
          }
          else {
            value[state] = ""
            value[stateNotes] = ""
          }
          value[workingUser] = adGroup.workingUser;

          infoExcel.push(value)
        }
      }
      console.log(infoExcel)
      await this.rootStore.requestStore.generateExcelForModifiedAds(this.rootStore.userStore.user.username + "_ads_modified_by_" + username, infoExcel);

      console.log(data)
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
  }
  }
}

export default TeamStore;
