import { observable, action } from "mobx";
//import i18n from "../../i18n";

class NewRegionSelectorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  // Control variables

  @observable Regions = [];
  @observable showAlert = false;
  @observable confirmAlert = false;

  @observable opened = false;

  totalR = [];

  @observable subregions = {};
  @observable clicked = {};

  @observable origin = "cityhall";
  @observable bigRegion = false; //True -> Regió adm1, False -> Regións del usuari

  goFunction = async (e, id, name, adm) => {
    console.log(this.origin)
    switch (this.origin) {
      //Statistics -> Stats
      case "statsNirc":
        this.rootStore.navigationStore.replace(
          "/statistics/statistics?region=" + id
        );
        this.rootStore.navigationStore.reload();
        break;

      //Working Area
      case "wa":
        await this.rootStore.mapListStore.newTab(id, name, adm)
        /*this.rootStore.navigationStore.replace(
          "/area" + await this.rootStore.mapListStore.getNewUrl()
        );
        this.rootStore.navigationStore.reload();*/
        break;

      case "cityhall":
        this.rootStore.navigationStore.replace(
          "/statistics/cityhall?region=" + id
        );
        this.rootStore.navigationStore.reload();
        break;

      case "cockpit":
        this.rootStore.cockpitStore.changeCity(id);
        break;
        
      case "command":
        this.rootStore.commandStore.changeCity(id);
        break;

      case "mapLicense":
        this.rootStore.navigationStore.replace("/censusMap?region="+id);
        this.rootStore.navigationStore.reload();
        break;
        
        //Command page
      default:
        this.rootStore.commandStore.changeCity(id);
        break;
    }
  
  };

  @action("save origin page") saveOrigin = async (page, region) => {
    this.origin = page;
    this.bigRegion = region;
  }

  @action("update property") updateProperty(key, value) {
    this[key] = value;
  }

  @action("find subRegions") findSubRegions = async (id, adm) => {
    try {
      let idWithAdm = {
        _id: id,
        adm: adm,
      };
      let res2 = await this.rootStore.requestStore.getChildRegionsDataForStats(
        idWithAdm
      );
      return res2.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get listRegions ids") getlistRegionsIds = async () => {
    try {
      if (this.bigRegion) {
        let res;
        let found = false;
        for (let i = 0; !found && i < 6; i++) {
          res = await this.rootStore.requestStore.searchBiggestRegion(i);
          found = res.data.length > 0;
        }
        this.totalR.push(res.data[0]);
        this.Regions = JSON.parse(JSON.stringify(this.totalR));
        this.totalR = [];

      } else {
        let user = await this.rootStore.sessionStore.getSession();
        let res = await this.rootStore.requestStore.getUserById(user.id);
        this.listRegions = res.data.assignedRegions;
        for (let i in this.listRegions) {
          let res = await this.rootStore.requestStore.getRegionForStats(
            this.listRegions[i]
          );
          this.totalR.push(res.data[0]);
        }
        this.Regions = JSON.parse(JSON.stringify(this.totalR));
        this.totalR = [];
        //console.log(this.Regions);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get subregions") getSubregions = async (it, id, adm) => {
    try {
      //console.log(it, id, adm);
      if (!(it in this.subregions)) {
        this.subregions[it] = {};
      }
      if (it in this.subregions && !(id in this.subregions[it])) {
        this.subregions[it][id] = [];
      }
      let test = await this.findSubRegions(id, adm);
      //console.log(id, test);
      this.subregions[it][id] = JSON.parse(JSON.stringify(test));
      //console.log(this.subregions);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Configure click") configureClicked = (it, pos) => {
    if (!(it in this.clicked)) {
      this.clicked[it] = {};
    }
    if (it in this.clicked && !(pos in this.clicked[it])) {
      this.clicked[it][pos] = false;
    }
    //console.log(this.clicked);
  };

  @action("Click On drop down") clickedOn = (it, pos, id, adm) => {
    if (!(it in this.clicked)) {
      this.clicked[it] = {};
    }
    if (it in this.clicked && !(pos in this.clicked[it])) {
      this.clicked[it][pos] = false;
    }
    if (!(it in this.subregions) || !(id in this.subregions[it])) {
      this.getSubregions(it, id, adm);
    }
    //console.log(it, pos, this.clicked, this.clicked[it]);
    this.clicked[it][pos] = !this.clicked[it][pos];
  };
}
export default NewRegionSelectorStore;
