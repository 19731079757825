import { observable, action, computed } from "mobx";
import i18n from "../../i18n";

class NewUserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable regions;
  assignedRegions = [];
  @observable myRegions;
  @observable initialized = false;
  @observable regionSearch = "";
  @observable showAlert = false;
  @observable confirmAlert = false;
  @observable tabsOpen = {};
  @observable compareRegions;
  @observable hiredRegions;
  @observable client = {};

  @observable selectorRegions = {
    fromOption: "-1",
    toOptions: "-1",
    value: "0",
    url: "",
    selectOptions: [],
  };

  @observable newUser = {};

  lowerCaseLetters = /[a-z]/g;
  upperCaseLetters = /[A-Z]/g;
  numbers = /[0-9]/g;
  specialChars = /[^\w\s]/gi;
  requiredLength = 8;

  @observable newUserForm = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
  };

  @observable passwordObject = {
    passwordRepeat: "",
    password: "",
  };

  @action("Initialize Store") initializeStore = async () => {
    this.regions = (
      await this.rootStore.requestStore.getRegions({
        limit: "9999999999",
        projection: "q$f$h$j",
      })
    ).data;

    this.myRegions = [];
    this.assignedRegions = [];
    this.compareRegions = [];
    this.initialized = true;

    this.hiredRegions = (
      await this.rootStore.requestStore.getAllHiredRegions()
    ).data;
    this.client = this.hiredRegions[0];

    let options = [];
    //document.getElementById("selectGroup").options;
    this.hiredRegions.forEach((hiredRegion) => {
      options.push({ value: hiredRegion.name, key: hiredRegion._id });
      //Así no
      //selectGroup.add(new Option(hiredRegion.name, hiredRegion._id, false));
    })
    console.log(options);
    this.selectorRegions.selectOptions = options;
    String(this.selectorRegions.selectOptions[0].key)
  };

  @action("Toggle the alert") toggleBadAlert = () => {
    this.updateProperty("showAlert", true);
    document.getElementById("danger-alert-newUser").innerHTML =
      i18n.t("newUser.alertData") + "<br>";

    if (!this.newUserForm.firstName.length > 0)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.nameMissing") + "<br>";
    if (!this.newUserForm.lastName.length > 0)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.surnameMissing") + "<br>";
    if (!this.getEmailIsValid)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.invalidEmail") + "<br>";
    if (!this.newUserForm.username.length > 0)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.usernameMissing") + "<br>";
    if (document.getElementById("selectGroup").value === "true")
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.groupMissing") + "<br>";

    if (!this.hasLetter)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.lowercaseMissing") + "<br>";
    if (!this.hasCapital)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.uppercaseMissing") + "<br>";
    if (!this.hasNumber)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.numberMissing") + "<br>";
    if (!this.hasSpecialChar)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.specialMissing") + "<br>";
    if (!this.hasLength)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.passwordLength") + "<br>";
    if (!this.passwordsMatch)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.equalPassword") + "<br>";
    setTimeout(() => {
      this.updateProperty("showAlert", false);
    }, 5000);
  };

  @action("Toggle the alert") toggleErrorAlert = (errorCode) => {
    this.updateProperty("showAlert", true);
    if (errorCode === "011000") {
      document.getElementById("danger-alert-newUser").innerHTML =
        i18n.t("newUser.alertData") + "<br>";
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.emailTaken") + "<br>";
    }
    if (errorCode === "111000") {
      document.getElementById("danger-alert-newUser").innerHTML =
        i18n.t("newUser.alertData") + "<br>";
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.userTaken") + "<br>";
    } else
      document.getElementById("danger-alert-newUser").innerHTML =
        i18n.t("newUser.alertError") + "<br>";

    setTimeout(() => {
      this.updateProperty("showAlert", false);
    }, 5000);
  };

  @action("Toggle the alert") toggleGoodAlert = () => {
    this.updateProperty("confirmAlert", true);
    document.getElementById("succes-alert-newUser").innerHTML = i18n.t(
      "newUser.alertConfirm"
    );
    setTimeout(() => {
      this.updateProperty("confirmAlert", false);
    }, 5000);
  };

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("update form property") updateFormProperty(key, value) {
    this.newUserForm[key] = value;
  }

  @action("update password values") updatePasswordForm = (key, value) => {
    this.passwordObject[key] = value;
  };

  @action("Select region") selectRegionToMine = async (target, regName, adm) => {
    this.assignedRegions.push(target.parentElement.children[1].id);
    //Open tabs
    let key = target.parentElement.children[1].id;
    let length = Object.keys(this.tabsOpen).length;
    let reg = {
      _id: key,
      name: regName,
      adm: adm,
    }
    if (length >= 5) {
      let regDel = {};
      regDel = this.orderOpenTabs(reg);
      if (regDel._id !== undefined) {
        delete this.tabsOpen[regDel._id];
        let del = -1;
        for (let i = 0; i < this.compareRegions.length; i++) {
          if (this.compareRegions[i]._id === regDel._id) del = i;
        }
        this.compareRegions.splice(del, 1);
        this.tabsOpen[reg._id] = reg.name;
        this.compareRegions.push(reg);
      }
    }
    else {
      this.compareRegions.push(reg);
      this.tabsOpen[reg._id] = reg.name;
    }
    //myRegions
    this.myRegions = (
      await this.rootStore.requestStore.getRegions({
        q: "in/$/" + this.assignedRegions,
        limit: "9999999999",
        projection: "q$f$h$j",
      })
    ).data;
  };

  @action("Deselect Region") deselectRegion = async (target) => {
    this.assignedRegions.splice(
      this.assignedRegions.indexOf(target.parentElement.children[1].id),
      1
    );

    if (this.assignedRegions.length <= 0) this.myRegions = [];
    else {
      this.myRegions = (
        await this.rootStore.requestStore.getRegions({
          q: "in/$/" + this.assignedRegions,
          limit: "9999999999",
          projection: "q$f$h$j",
        })
      ).data;
    }
    //Remove open tabs
    let key = target.parentElement.children[1].id;
    if (this.tabsOpen[key] !== undefined) {
      delete this.tabsOpen[key];
      let del = -1;
      for (let i = 0; i < this.compareRegions.length; i++) {
        if (this.compareRegions[i]._id === key) del = i;
      }
      this.compareRegions.splice(del, 1);
      //Reorganize open tabs
      let addRegion = true;
      for (let j = 0; j < this.myRegions.length && addRegion; j++) {
        let reg = this.myRegions[j];
        if (this.tabsOpen[reg._id] === undefined) {
          let regDel = this.orderOpenTabs(reg);
          if (regDel._id !== undefined) {
            this.tabsOpen[reg._id] = reg.name;
            this.compareRegions.push(reg);
            addRegion = false;
          }
        }
      }
    }
  };

  @action("Get Regions") getSuggestedRegions = async () => {
    try {
      const inputValue = this.regionSearch.trim().toLowerCase();
      const inputLength = inputValue.length;

      if (inputLength > 0) {
        this.regions = await this.rootStore.requestStore.searchRegionsByName(
          inputValue
        );
      } else {
        this.regions = (
          await this.rootStore.requestStore.getRegions({
            limit: "9999999999",
            projection: "q$f$h$j",
          })
        ).data;
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @computed get hasLetter() {
    return !!this.passwordObject.password.match(this.lowerCaseLetters);
  }
  @computed get hasCapital() {
    return !!this.passwordObject.password.match(this.upperCaseLetters);
  }
  @computed get hasNumber() {
    return !!this.passwordObject.password.match(this.numbers);
  }
  @computed get hasSpecialChar() {
    return (
      !!this.passwordObject.password.match(this.specialChars) ||
      this.passwordObject.password.match("_")
    );
  }
  @computed get hasLength() {
    return this.passwordObject.password.length >= this.requiredLength;
  }
  @computed get passwordsMatch() {
    return (
      this.passwordObject.password.length > 0 &&
      this.passwordObject.password === this.passwordObject.passwordRepeat
    );
  }

  @computed get getPasswordFormIsValid() {
    return (
      this.hasCapital &&
      this.hasLength &&
      this.hasLetter &&
      this.hasNumber &&
      this.hasSpecialChar &&
      this.passwordsMatch
    );
  }

  @computed get getEmailIsValid() {
    let emailSplitted = this.newUserForm.email.split("@");
    console.log(emailSplitted)
    if (emailSplitted.length !== 2) return false;
    let domain = emailSplitted[1].split(".");

    if (domain.length === 3 && domain[1] === "gov" && domain[2] === "uk") {
      return (
        domain.length === 3 &&
        emailSplitted[0].length > 0 &&
        domain[0].length > 0 &&
        domain[1].length > 0 &&
        domain[2].length > 0
      );
    } else {

      return (
        domain.length === 2 &&
        emailSplitted[0].length > 0 &&
        domain[0].length > 0 &&
        domain[1].length > 0
      );
    }
  }

  @computed get getUserIsValid() {
    return (
      this.newUserForm.firstName.length > 0 &&
      this.newUserForm.lastName.length > 0 &&
      this.getEmailIsValid &&
      this.newUserForm.username.length > 0 &&
      document.getElementById("selectGroup").value !== "true"
    );
  }

  @computed get getDataIsValid() {
    return this.getPasswordFormIsValid && this.getUserIsValid;
  }

  @action("Register new User") register = async () => {
    if (this.getDataIsValid) {
      let body = {
        email: this.newUserForm.email,
        username: this.newUserForm.username,
        isSuperuser: false,
        isSupervisor: document.getElementById("supervisorCheckbox").checked,
        password: this.passwordObject.password,
        firstName: this.newUserForm.firstName,
        lastName: this.newUserForm.lastName,
        group: this.client.group,
        regions: this.assignedRegions,
        tabsOpen: this.tabsOpen,
      };
      console.log("Register body: ", body);
      let res = await this.rootStore.requestStore.register(body);
      console.log("Res: ", res);
      if (res !== undefined && res.statusText === "OK" && !res.data.error) {
        this.toggleGoodAlert();
        this.myRegions = [];
        this.assignedRegions = [];
        this.tabsOpen = {};
        this.compareRegions = [];
        this.newUserForm = {
          firstName: "",
          lastName: "",
          email: "",
          username: "",
        };
        this.passwordObject = {
          passwordRepeat: "",
          password: "",
        };

        this.newUser = res.config.params;
      } else {
        console.log(res);
        this.toggleErrorAlert(res.data.code);
      }
    } else {
      this.toggleBadAlert();
      console.log("The data you are entering is not valid");
    }
  };

  orderOpenTabs(reg) {
    let tabs = this.tabsOpen;
    //Check adms to get the highest and lowest regions in the open tabs
    let regDel = {};
    let regMax = {
      _id: "",
      name: "",
      adm: "adm0"
    }
    let regMin = {
      _id: "",
      name: "",
      adm: "adm99"
    }
    //Search for the min and max adms in compareRegions
    for (let i = 0; i < this.compareRegions.length; i++) {
      let region = this.compareRegions[i];
      if (region.adm > regMax.adm) regMax = region;
      if (region.adm < regMin.adm) regMin = region;
    }
    //Substitute region if adm is higher or lower than the max and min
    if (reg.adm < regMin.adm) {
      regDel = regMin;
    }
    else if (reg.adm > regMax.adm) {
      regDel = regMax;
    }
    this.tabsOpen = tabs;
    return regDel;
  }

  @action("update nested attribute") updateNestedProperty = (attrPath, value) => {
    console.log(attrPath, value);
    let path = attrPath.split(".");
    this[path[0]][path[1]] = value;
    console.log(this[path[0]][path[1]]);
    this.changeSelectGroup();
  };

  @action("Change selected group") changeSelectGroup() {
    this.hiredRegions.forEach((hiredRegion) => {
      if (hiredRegion._id === this.selectorRegions.fromOption) this.client = hiredRegion;
    })
  }

  sendNewUserMail = async () => {
    let user = this.newUser;
    let lang = i18n.language;

    let body = "";
    let htmlBody = "";
    let subject = "";
    switch (lang) {
      case "ca":
        body = "Bon dia " + user.firstName + ",\nDes de l'equip de Talk&Code et donem la benvinguda, i et fem arribar les credencials per accedir a la plataforma.\n\nURL: https://stlpro.talkandcode.com/login \nUsuari: " + user.username + "\nParaula de pas: " + user.password + "\nCodi: " + this.client.code + "\n\nEt recomanem que canviis el mot de pas el més aviat possible, per seguretat. \nSi tens qualsevol dubte ens el podràs fer arribar a través de la pestanya de contacte dins de la plataforma STLPRO i t'ajudarem de bon grat a resoldre'l. \n\nAtentament,\n\nEquip de Suport\n\nTalk&Code | The Smart Tourism Platform\n";
        htmlBody = "Bon dia " + user.firstName + ",<br/><br/>Des de l'equip de Talk&Code et donem la benvinguda, i et fem arribar les credencials per accedir a la plataforma.<br/><br/>URL: https://stlpro.talkandcode.com/login <br/> Usuari: " + user.username + "<br/>Paraula de pas: " + user.password + "<br/>Codi: " + this.client.code + "<br/>Si tens qualsevol dubte ens el podràs fer arribar a través de la pestanya de contacte dins de la plataforma STLPRO i t'ajudarem de bon grat a resoldre'l. <br/><br/>Atentament,<br/><strong>Equip de Suport</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
        subject = "Mail de benvinguda a l'Estació de Treball per l'equip d'Inspecció";
        break;
      case "es":
        body = "Buenos días " + user.firstName + ",\nDesde el equipo de Talk&Code te damos la bienvenida, y te hacemos llegar las credenciales para acceder a la plataforma.\n\nURL: https://stlpro.talkandcode.com/login \nUsuario: " + user.username + "\nContraseña: " + user.password + "\nCódigo: " + this.client.code + "\n\nTe recomendamos que cambies la contraseña lo antes posible, por seguridad. \nSi tienes cualquier duda nos lo podrás notificar a través de la pestaña de contacto dentro de la plataforma STLPRO y te ayudaremos de buen grado a resolverlo. \n\nAtentamente,\n\nEquipo de Soporte\n\nTalk&Code | The Smart Tourism Platform\n";
        htmlBody = "Buenos días " + user.firstName + ",<br/><br/>Desde el equipo de Talk&Code te damos la bienvenida, y te hacemos llegar las credenciales para acceder a la plataforma.<br/><br/>URL: https://stlpro.talkandcode.com/login <br/>Usuario: " + user.username + "\nContraseña: " + user.password + "\nCódigo: " + this.client.code + "<br/>Si tienes cualquier duda nos lo podrás notificar a través de la pestaña de contacto dentro de la plataforma STLPRO y te ayudaremos de buen grado a resolverlo. <br/><br/>Atentamente,<br/><strong>Equipo de Soporte</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
        subject = "Mail de bienvenida a la Estación de Trabajo para el equipo de Inspección";
        break;
      case "fr":
        body = "Bonjour " + user.firstName + ",\nL'équipe de Talk&Code vous souhaite la bienvenue et vous envoie les identifiants pour accéder à la plateforme.\n\nURL: https://stlpro.talkandcode.com/login \nUtilisateur: " + user.username + "\nMot de passe: " + user.password + "\nCode: " + this.client.code + "\n\nNous vous recommandons de modifier votre mot de passe dès que possible, pour des raisons de sécurité. \nSi vous avez des questions, vous pouvez nous en faire part via l'onglet contact de la plateforme STLPRO et nous nous ferons un plaisir de vous aider à les résoudre. \n\nSincèrement, \n\nl'équipe de support\n\nTalk&Code | The Smart Tourism Platform\n";
        htmlBody = "Bonjour " + user.firstName + ",<br/><br/>L'équipe de Talk&Code vous souhaite la bienvenue et vous envoie les identifiants pour accéder à la plateforme.<br/><br/>URL: https://stlpro.talkandcode.com/login <br/>Utilisateur: " + user.username + "<br/>Mot de passe: " + user.password + "<br/>Code: " + this.client.code + "<br/>Si vous avez des questions, vous pouvez nous en faire part via l'onglet contact de la plateforme STLPRO et nous nous ferons un plaisir de vous aider à les résoudre. <br/><br/>Sincèrement, <br/><strong>l'équipe de support</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
        subject = "Courrier de bienvenue au poste de travail de l'équipe d'inspection";
        break;
      case "it":
        body = "Buongiorno " + user.firstName + ",\nIl team di Talk&Code vi dà il benvenuto e vi invia le credenziali di accesso alla piattaforma.\n\nURL: https://stlpro.talkandcode.com/login \nUtente: " + user.username + "\nPassword: " + user.password + "\nCodice: " + this.client.code + "\n\nPer motivi di sicurezza, si consiglia di modificare la password il prima possibile. \nSe avete domande, potete comunicarcele attraverso la scheda di contatto all'interno della piattaforma STLPRO e saremo lieti di aiutarvi a risolverle. \n\nSinceramente, \n\nil servizio di assistenza\n\nTalk&Code | The Smart Tourism Platform\n";
        htmlBody = "Buongiorno " + user.firstName + ",<br/><br/>Il team di Talk&Code vi dà il benvenuto e vi invia le credenziali di accesso alla piattaforma.<br/><br/>URL: https://stlpro.talkandcode.com/login <br/>Utente: " + user.username + "<br/>Password: " + user.password + "<br/>Codice: " + this.client.code + "<br/><br/>Se avete domande, potete comunicarcele attraverso la scheda di contatto all'interno della piattaforma STLPRO e saremo lieti di aiutarvi a risolverle. <br/>Sinceramente, <br/><strong>il servizio di assistenza</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
        subject = "Mail di benvenuti alla postazione di lavoro del team di ispezione";
        break;

      default:
        body = "Hello " + user.firstName + ",\nFrom the Talk&Code team we welcome you, and we provide you with the credentials to access the platform.\n\nURL: https://stlpro.talkandcode.com/login \nUser: " + user.username + "\nPassword: " + user.password + " \nCode: " + this.client.code + "\n\nWe advise that you change the password as soon as possible, as a safety measure. \nIf you have any doubts you can let us know through the contact window in the STLPRO platform and we will be glad to assist you. \n\nSincerely, \n\nSupport Team \n\nTalk&Code | The Smart Tourism Platform\n";
        htmlBody = "Hello " + user.firstName + ",<br/><br/>From the Talk&Code team we welcome you, and we provide you with the credentials to access the platform.<br/><br/>URL: https://stlpro.talkandcode.com/login <br/>User: " + user.username + "<br/>Password: " + user.password + "<br/>Code: " + this.client.code + "<br/>If you have any doubts you can let us know through the contact window in the STLPRO platform and we will be glad to assist you. <br/><br/>Sincerely, <br/><strong>Support Team</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
        subject = "Welcoming mail to the Workstation for the Inspection team"
        break;
    }
    body = body + "P Before printing think about the environment C \nThis e-mail and any attachments thereto are classified as confidential information and as Talk&Code copyright, are intended for use by the addressee(s) named herein only and may contain legally privileged and/or confidential information. If you are not the recipient of this e-mail, you are hereby notified that any distribution or copying of this e-mail, and any attachments thereto, is strictly prohibited. If you have received this e-mail in error, please notify us immediately, permanently delete the original including attachments, and destroy any copy or printout thereof."
    htmlBody = htmlBody + "<p style='color:green'>P Before printing think about the environment C</p><p> This e-mail and any attachments thereto are classified as confidential information and as Talk&Code copyright, are intended for use by the addressee(s) named herein only and may contain legally privileged and/or confidential information. If you are not the recipient of this e-mail, you are hereby notified that any distribution or copying of this e-mail, and any attachments thereto, is strictly prohibited. If you have received this e-mail in error, please notify us immediately, permanently delete the original including attachments, and destroy any copy or printout thereof.</p>"
    console.log("Body: ", body);
    console.log("htmlBody: ", htmlBody);
    await this.rootStore.requestStore.sendBasicMail(
      subject, //subject
      body, //body (plain text)
      user.username, //user
      this.client.group, //group
      htmlBody//body (html text)
    );
  };

}
export default NewUserStore;
