import { observable, action } from "mobx";
import i18n from "../../i18n";

class CockpitStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable cityName;
    @observable cityId;
    @observable cityAdm;
    @observable errorGroupAdsCount;
    @observable complianceData = null;
    @observable hasSubregions;
    @observable dateInfo = "03-2024"
    @observable typeSelector = {
        fromOption: "-1",
        toOption: "-1",
        value: "0",
        url: "",
        selectOptions: [
            { key: "all", value: i18n.t("roomType.all") },
            { key: "house", value: i18n.t("roomType.house") },
            { key: "rural", value: i18n.t("roomType.rural") },
            { key: "private", value: i18n.t("roomType.private") },
            { key: "shared", value: i18n.t("roomType.shared") },
            { key: "apartment", value: i18n.t("roomType.apartment") },
            { key: "hotel", value: i18n.t("roomType.hotel") },
            { key: "bib", value: i18n.t("roomType.bib") },
            { key: "pension", value: i18n.t("roomType.pension") },
            { key: "lodge", value: i18n.t("roomType.lodge") },
            { key: "camping", value: i18n.t("roomType.camping") },
            { key: "ship", value: i18n.t("roomType.ship") },
        ],
      }

    @action("Create options for filters") createOptions = () => {
    this.occupancyFilter.selectOptions = [
        { key: "all", value: i18n.t("roomType.all") },
        { key: "house", value: i18n.t("roomType.house") },
        { key: "rural", value: i18n.t("roomType.rural") },
        { key: "private", value: i18n.t("roomType.private") },
        { key: "shared", value: i18n.t("roomType.shared") },
        { key: "apartment", value: i18n.t("roomType.apartment") },
        { key: "hotel", value: i18n.t("roomType.hotel") },
        { key: "bib", value: i18n.t("roomType.bib") },
        { key: "pension", value: i18n.t("roomType.pension") },
        { key: "lodge", value: i18n.t("roomType.lodge") },
        { key: "camping", value: i18n.t("roomType.camping") },
        { key: "ship", value: i18n.t("roomType.ship") },
    ]
    };

    @action("update selector for complianceData") updateComplianceData = (attrName, value) =>{
        this.updateNestedProperty(attrName, value);
        switch (value) {
            case "all":
                this.complianceData = this.complianceStats.all
                break;
            case "house":
                this.complianceData = this.complianceStats.house
                break;
            case "rural":
                this.complianceData = this.complianceStats.rural
                break;
            case "private":
                this.complianceData = this.complianceStats.private
                break;
            case "shared":
                this.complianceData = this.complianceStats.shared
                break;
            case "apartment":
                this.complianceData = this.complianceStats.apartment
                break;
            case "bib":
                this.complianceData = this.complianceStats.bib
                break;
            case "pension":
                this.complianceData = this.complianceStats.pension
                break;
            case "camping":
                this.complianceData = this.complianceStats.camping
                break;
            case "ship":
                this.complianceData = this.complianceStats.ship
                break;
        
            default:
                this.complianceData = this.complianceStats.all
                break;
        }
    } 

    @action("update attribute") updateProperty = (attrName, value) => {
        //console.log("UpdateProperty Start",attrName,this[attrName],value)
        this[attrName] = value;
        //console.log("UpdateProperty End",attrName,this[attrName],value)
      };
    
      @action("update nested attribute") updateNestedProperty = (
        attrPath,
        value
      ) => {
        console.log(attrPath, value)
        let path = attrPath.split(".");
        this[path[0]][path[1]] = value;
      };

    @action("return month") returnAvailableMonth = async (
        data,
        month,
        cont
    ) => {
        //Si hay datos del mes, devuelve el mes
        if (data[month] && Object.keys(data[month]).length > 0) return month;
        else {
            //Si el contador es 12 ya ha pasado por todo los meses (Del 0 al 11) y por lo tanto que devuelva error
            if (cont === 12) {
                return null;
            }
            //Si el mes es 0, vuelve al 11 y sino se resta uno. Falta volver a llamar a la funcion con parametros correctos
            else {
                let myMonth = month === 0 ? 11 : month - 1;
                return this.returnAvailableMonth(data, myMonth, cont + 1);
            }
        }
    };

    @action("save current changes") initiateStore = async () => {
        let search = this.rootStore.navigationStore.history.location.search;
        if (!!search || search === "") {
            if (search[0] === "?") {
                search = search.substr(1);
                let elemParams = search.split("=");
                if (elemParams[0] === "region") {
                    let city =
                        await this.rootStore.requestStore.getCityHallData(
                            elemParams[1]
                        );
                    let cityname =
                        await this.rootStore.requestStore.getCityHallName(
                            elemParams[1]
                        );
                    console.log(city, cityname);
                    this.cityId = city._id;
                    this.cityAdm = cityname.adm;
                    this.cityName = cityname.name;
                    this.hasSubregions = cityname.hasSubregions
                    this.complianceStats = city.complianceStats
                    this.complianceData = city.complianceStats.all;
                    if (city.dateInfo != undefined) this.dateInfo = city.dateInfo;
                }
            } else {
                let userData = (
                    await this.rootStore.requestStore.getUserAssignedRegions(
                        this.rootStore.userStore.getUser().id
                    )
                ).data;
                this.rootStore.navigationStore.replace(
                    "/cockpit?region=" + userData.assignedRegions[0]
                );
                this.rootStore.navigationStore.reload();
            }
        }
        this.createOptions();
    };

    /* @action("go to filter") tableToWA = (row, column) => {
            if (row !== "num_users") {
                if (row === "total_ads") {
                    if (column !== "total")
                        this.rootStore.navigationStore.openInNewTab(
                            "area?focusedRegion=" +
                                this.cityId +
                                "&platf=" +
                                column[0].toUpperCase() +
                                column.substring(1)
                        );
                    else
                        this.rootStore.navigationStore.openInNewTab(
                            "/area?focusedRegion=" + this.cityId
                        );
                } else {
                    if (column !== "total")
                        this.rootStore.navigationStore.openInNewTab(
                            "area?focusedRegion=" +
                                this.cityId +
                                "&cityhall=" +
                                row +
                                "&platf=" +
                                column[0].toUpperCase() +
                                column.substring(1)
                        );
                    else
                        this.rootStore.navigationStore.openInNewTab(
                            "/area?focusedRegion=" +
                                this.cityId +
                                "&cityhall=" +
                                row
                        );
                }
            } 
        
    }; */

    @action("Change city") changeCity = async (id) => {
        console.log(id);
        let city = await this.rootStore.requestStore.getCityHallData(id);
        this.rootStore.navigationStore.replace("/cockpit?region=" + city._id);
        this.rootStore.navigationStore.reload();
    };

    @action("Table to WA") tableToWA = (website, stat, rules) => {
        if (!this.hasSubregions){
            let roomtype = this.typeSelector.value;
            let status = "";
            let rule = "";
            switch (stat) {
                case "correct":
                    status = "exc";
                    break;
                case "correcto":
                    status = "exc";
                    break;
                case "leve":
                    status = "hig";
                    break;
                case "posibleFraude":
                    status = "low";
                    break;
                case "posfraude":
                    status = "low";
                    break;
                case "fraude":
                    status = "def";
                    break;
                default:
                    break;
            }
            switch (rules) {
                case "show_text":
                    rule = "show";
                    break;
                case "registry_match":
                    rule = "reg";
                    break;
                case "radi_municipality":
                    rule = "rad";
                    break;
                case "pattern_match":
                    rule = "pat";
                    break;
                case "duplicated":
                    rule = "dup";
                    break;
                case "commercial_name":
                    rule = "com";
                    break;
                default:
                    break;
            }
            if (website == "Homeaway") website = "vrbo"
            console.log(website, stat, rules);
            console.log(website, status, rule);
            if (website !== "total") {
                if (rules !== "total") {
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&platf=" +
                            website[0].toUpperCase() +
                            website.substring(1) +
                            "&compliance=" +
                            status +
                            "$" +
                            rule +
                            "&roomGroup=" +
                            roomtype
                    );
                } else {
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&platf=" +
                            website[0].toUpperCase() +
                            website.substring(1) +
                            "&compliance=" +
                            status +
                            "&roomGroup=" +
                            roomtype
                    );
                }
            } else {
                if (rules !== "total") {
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&compliance=" +
                            status +
                            "$" +
                            rule +
                            "&roomGroup=" +
                            roomtype
                    );
                } else {
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&compliance=" +
                            status +
                            "&roomGroup=" +
                            roomtype
                    );
                }
            }
        }
    };

    @action("Download Excel") downloadExcel = async (userName, website, stat, rules) => {
        let status = "";
        let rule = "";
        let roomtype = this.typeSelector.value; 
        status = stat;
        /*switch (stat) {
            case "correct":
                status = "exc";
                break;
            case "correcto":
                status = "exc";
                break;
            case "leve":
                status = "hig";
                break;
            case "posibleFraude":
                status = "low";
                break;
            case "posfraude":
                status = "low";
                break;
            case "fraude":
                status = "def";
                break;
            default:
                status = stat
                break;
        }*/
        switch (rules) {
            case "show_text":
                rule = "show";
                break;
            case "registry_match":
                rule = "reg";
                break;
            case "radi_municipality":
                rule = "rad";
                break;
            case "pattern_match":
                rule = "pat";
                break;
            case "duplicated":
                rule = "dup";
                break;
            case "commercial_name":
                rule = "com";
                break;
            default:
                rule = rules
                break;
        }
            //if (website == "Homeaway") website = "vrbo"
        console.log(website, stat, rules);
        console.log(website, status, rule);
        let ans;
        if (website !== "total") {
            if (rules !== "total") {
                ans = await this.rootStore.requestStore.getAdsForExcel(
                    { _id: this.cityId, adm: this.cityAdm },
                    "error=0&platf=" +
                    website[0].toUpperCase() +
                    website.substring(1) +
                    "&compliance=" +
                    status +
                    "$" +
                    rule +
                    "&roomGroup=" +
                    roomtype
                );
            } else {
                ans = await this.rootStore.requestStore.getAdsForExcel(
                    { _id: this.cityId, adm: this.cityAdm },
                    "error=0&platf=" +
                    website[0].toUpperCase() +
                    website.substring(1) +
                    "&compliance=" +
                    status +
                    "&roomGroup=" +
                    roomtype
                );
            }
        } else {
            if (rules !== "total") {
                ans = await this.rootStore.requestStore.getAdsForExcel(
                    { _id: this.cityId, adm: this.cityAdm },
                    "error=0&compliance=" +
                    status +
                    "$" +
                    rule +
                    "&roomGroup=" +
                    roomtype
                );
            } else {
                ans = await this.rootStore.requestStore.getAdsForExcel(
                    { _id: this.cityId, adm: this.cityAdm },
                    "error=0&compliance=" +
                    status +
                    "&roomGroup=" +
                    roomtype
                );
            }
        }
        console.log(ans)
        let auxAds = [];
        if (ans && ans.data && ans.data.ads) {
            console.log(ans.data.ads);
            let ads = ans.data.ads;

            for (let i = 0; i < ads.length; i++) {
            // let municipality = await this.generateVal(ads[i], "municipality")
            // let county = await this.generateVal(ads[i], "county")
            // let title = await this.generateVal(ads[i], "title")
            // let commercialType = await this.generateVal(ads[i], "commercialType")
            // let transactionType = await this.generateVal(ads[i], "transactionType")
            // let price = await this.generateVal(ads[i], "price")
            // let surface = await this.generateVal(ads[i], "surface")
            //plataforma, id anunci (sense plataforma), url, llicència
            // if (municipality &&  county && title && commercialType && transactionType && price && surface) {
            let platform = "Plataforma";
            let id = "ID anunci";
            let url = "URL";
            let license = "Llicencia";
            let val = {};

            val[platform] = ads[i].id.slice(0, 6);
            val[id] = ads[i].id.slice(6);
            val[url] = ads[i].url;
            val[license] = ads[i].licCode;

            auxAds.push(val);
            //}
            }
        }
        // console.log(auxAds)
        if (auxAds.length > 0) {
            if (auxAds.length > 20000) {
            window.alert("excelTooMuch");
            //await this.rootStore.requestStore.generateExcel(userName, auxAds)
            } else {
            console.log("GOING TO GENERATE THE EXCEL");
            await this.rootStore.requestStore.generateExcel(userName, auxAds);
            // window.alert(i18n.t("polygon.searches.excelCreated"))
            }
        } else {
            //Poner el caso de que demasiados anuncios
            window.alert("noExcelCreated");
        }
    }
}

export default CockpitStore;
