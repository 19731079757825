import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
//import OverlayTrigger from "react-bootstrap/OverlayTrigger";
//import Tooltip from "react-bootstrap/Tooltip";

@inject(
    "userStore",
    "newRegionSelectorStore",
    "navigationStore",
    "sessionStore"
)
@observer
class NewRegionSelectorCard extends Component {
    async componentDidMount() {
        try {
            console.log(this.props.origin, this.props.regBool)
            await this.props.newRegionSelectorStore.saveOrigin(this.props.origin, this.props.regBool)
            if (this.props.subreg) {
                await this.props.newRegionSelectorStore.getSubregions(
                    this.props.it,
                    this.props.regid,
                    this.props.regadm
                );
                await this.props.newRegionSelectorStore.configureClicked(
                    this.props.it,
                    this.props.pos
                );
            }
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    componentWillUnmount() {}

    render() {
        const t = this.props.t;
        //console.log(this.props);
        return (
            <div className="card">
                <div>
                    <div
                        id={"heading-area" + this.props.regid}
                        className="card-header row"
                        role="tab"
                    >
                        <p className="col-md-10 row">
                            {this.props.subreg ? (
                                <svg
                                    className="svg-icon-angle-down-stats"
                                    data-name="fas fa-angle-down"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 40.11 26.16"
                                    onClick={async (e) => {
                                        await this.props.newRegionSelectorStore.clickedOn(
                                            this.props.it,
                                            this.props.pos,
                                            this.props.regid,
                                            this.props.regadm
                                        );
                                    }}
                                >
                                    <path
                                        className="cls-1"
                                        d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                                        transform="translate(-19.91 -28.02)"
                                    />
                                </svg>
                            ) : null}
                            {this.props.regname + " " + this.props.regadm}
                        </p>
                        <div className="col-md-2">
                            {//Ficar aqui restriccions per a que no es mostri el goto
                            /*(this.props.regadm !== "adm1" &&
                                this.props.regadm !== "adm2" &&
                                this.props.regadm !== "adm3") ||
                            this.props.newRegionSelectorStore.origin !==
                                "command" ||
                            this.props.newRegionSelectorStore.origin !==
                                "statsNirc" ||
                            this.props.newRegionSelectorStore.origin ===
                                "cityhall" ? (*/
                                <button
                                    className="btn btn-outline-info"
                                    onClick={(e) => {
                                        this.props.newRegionSelectorStore.goFunction(
                                            e,
                                            this.props.regid,
                                            this.props.regname,
                                            this.props.regadm
                                        );
                                    }}
                                >
                                    {t("stats.go")}
                                </button>
                            /*) : null*/}
                        </div>
                    </div>
                </div>
                {this.props.subreg &&
                this.props.it in this.props.newRegionSelectorStore.clicked &&
                this.props.pos in
                    this.props.newRegionSelectorStore.clicked[this.props.it] &&
                this.props.newRegionSelectorStore.clicked[this.props.it][
                    this.props.pos
                ] ? (
                    <div className="card-body">
                        <div
                            id={
                                "accordion-areaID" +
                                this.props.it +
                                this.props.regid
                            }
                            className="accordion md-accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            {this.props.newRegionSelectorStore.subregions[
                                this.props.it
                            ][this.props.regid].map((region, i) => {
                                //console.log(region.name, i);
                                return (
                                    <NewRegionSelectorCard
                                        key={"Card " + this.props.it + "-" + i}
                                        regname={region.name}
                                        regadm={region.adm}
                                        subreg={region.hasSubregions}
                                        regid={region._id}
                                        it={this.props.it + 1}
                                        pos={i}
                                        t={this.props.t}
                                        newRegionSelectorStore={
                                            this.props.newRegionSelectorStore
                                        }
                                        origin={this.props.origin}
                                        regBool={this.props.regBool}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
export default withTranslation()(NewRegionSelectorCard);
