import { observable, action } from "mobx";

class GroupEditorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  // @observable original;
  @observable ads;
  @observable showAlert = false;
  @observable confirmAlert = false;
  @observable reason = "";
  @observable workingUserOnCreate = false;
  @observable showModal = false;
  @observable workingUserModal = false;
  @observable individualModal = false

  getAdsAndAdGroupByCode = async (code) => {
    try {
      this.lastId = (
        await this.rootStore.requestStore.getLastAdGroup()
      ).data[0]._id;
      this.original = (
        await this.rootStore.requestStore.getAdGroupByCode(code)
      ).data.groups[0];
      await this.getAds();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("update checkbox filter") updateCheckboxListFilter = (ad) => {
    let adClasses = document.getElementById("checkbox" + ad.id);
    if (adClasses.classList.contains("checked")) {
      adClasses.className = adClasses.className.replace("checked", "nocheck");
    } else {
      adClasses.className = adClasses.className.replace("nocheck", "checked");
    }
    ad.clicked = !ad.clicked;
  };

  @action("Get all ads from original group ad") getAds = async () => {
    try {
      let params = {
        a: "in/$/" + this.original.childrenId,
        projection: "a$i$k$geo$h$n$o$p$j$l$q",
        limit: this.original.childrenId.length,
      };
      let res = await this.rootStore.requestStore.getMultipleAdsForGroupEditor(
        this.original.childrenId,
        params
      );

      if (res === undefined) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-groupeditor").innerHTML =
          "El grup no s'ha pogut crear. Siusplau, intenti-ho més tard.";
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      } else {
        this.ads = res.data;
        this.ads.forEach((ad) => {
          ad.index = 0;
          ad.clicked = false;
        });
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Create New Group Ad with the selected Ads from original")
  createNewGroup = async (workingUser) => {
    this.original.childrenId = [];
    this.original.childrenGeometry = [];
    let newAdGroup = {
      _id: "" + (parseInt(this.lastId) + 1),
      childrenId: [],
      childrenGeometry: [],
      discardedChildrenId: [],
    };
    this.lastId = "" + (parseInt(this.lastId) + 1);

    /********COPIA DE DADES DE L'AD**********/

    let websitesOriginal = new Set();
    let websitesCopia = new Set();
    let first = true;
    this.ads.forEach((ad) => {
      if (ad.clicked) {
        if (first) {
          newAdGroup.thumbnail = ad.images[0];
          first = false;
        }
        websitesCopia.add(ad.website);
        newAdGroup.childrenId.push(ad.id);
        newAdGroup.childrenGeometry.push(ad.geometry);
        this.original.discardedChildrenId.push(ad.id);
        ad.adGroupCode = newAdGroup._id;
        ad.adIsNew = true;
      } else {
        websitesOriginal.add(ad.website);
        this.original.childrenId.push(ad.id);
        this.original.childrenGeometry.push(ad.geometry);
      }
    });
    this.original.childrenWebsite = Array.from(websitesOriginal);
    newAdGroup.childrenWebsite = Array.from(websitesCopia);
    //newAdGroup.workingUser = this.original.workingUser;
    newAdGroup.reason = this.reason;

    //Mira si hi hagut alguna modificació
    if (!first) {
      let discarded = await this.rootStore.requestStore.searchDiscardedAds(
        newAdGroup.childrenId
      );
      /********INSERT BD*******/

      if (discarded.data && discarded.data[0]) {
        let dAdGroup = discarded.data[0];
        newAdGroup._id = dAdGroup._id;
        for (discarded of dAdGroup.discardedChildrenId) {
          if (!newAdGroup.childrenId.includes(discarded))
            newAdGroup.discardedChildrenId.push(discarded);
        }
        await this.rootStore.requestStore.modifyAdGroup(newAdGroup);
      }
      //Post per a l'adGroup Nou
      else await this.rootStore.requestStore.newAdGroup(newAdGroup);
      //Put per a l'adGroup Original
      await this.rootStore.requestStore.modifyAdGroup(this.original);
      //Put per a tots els ads
      await this.rootStore.requestStore.modifyAdList(
        newAdGroup.childrenId,
        newAdGroup._id
      );

      if (workingUser) {
        this.rootStore.requestStore.changeWorkingNewForGroupEditor(
          newAdGroup._id,
          this.rootStore.userStore.getUser().username,
          "GroupEditor",
          this.rootStore.userStore.getUser().username,
          [this.rootStore.userStore.getUser().username]
        );
      } else {
        this.rootStore.requestStore.changeWorkingNewForGroupEditor(
          newAdGroup._id,
          null,
          "GroupEditor",
          this.rootStore.userStore.getUser().username,
          []
        );
      }

      //Get all ads again
      this.getAds();
      return newAdGroup._id;
    }
    return parseInt(this.lastId);
  };

  @action("Create New Group Ad with the selected Ads from original")
  createNewIndividualGroup = async (workingUser) => {
    this.original.childrenId = [];
    this.original.childrenGeometry = [];
    //let idLast = this.lastId;

    /********COPIA DE DADES DE L'AD**********/

    let websitesOriginal = new Set();
    let newAdGroups = [];
    let somethingDone = false;
    let arrayAds = [];
    this.ads.forEach((ad) => {
      let newAdGroup = {
        _id: "" + (parseInt(this.lastId) + 1),
        childrenId: [],
        childrenGeometry: [],
        discardedChildrenId: [],
      };
      if (ad.clicked) {
        somethingDone = true;
        arrayAds.push(ad.id);
        newAdGroup.thumbnail = ad.images[0];
        newAdGroup.childrenId = [ad.id];
        newAdGroup.childrenGeometry = [ad.geometry];
        this.original.discardedChildrenId.push(ad.id);
        ad.adGroupCode = newAdGroup._id;
        ad.adIsNew = true;
        newAdGroup.childrenWebsite = ad.website;
        //newAdGroup.workingUser = this.original.workingUser;
        newAdGroups.push(newAdGroup);
        this.lastId = "" + (parseInt(this.lastId) + 1);
      } else {
        websitesOriginal.add(ad.website);
        this.original.childrenId.push(ad.id);
        this.original.childrenGeometry.push(ad.geometry);
      }
    });
    this.original.childrenWebsite = Array.from(websitesOriginal);
    let idsArray = [];
    //Mira si hi hagut alguna modificació
    if (somethingDone) {
      /********INSERT BD*******/
      //Post per als adGroups Nous

      for (let newAdGroup of newAdGroups) {
        let discarded = await this.rootStore.requestStore.searchDiscardedAds(
          newAdGroup.childrenId
        );
        /********INSERT BD*******/

        if (discarded.data && discarded.data[0]) {
          let dAdGroup = discarded.data[0];
          newAdGroup._id = dAdGroup._id;
          for (discarded of dAdGroup.discardedChildrenId) {
            if (!newAdGroup.childrenId.includes(discarded))
              newAdGroup.discardedChildrenId.push(discarded);
          }
          idsArray.push(newAdGroup._id);
          await this.rootStore.requestStore.modifyAdGroup(newAdGroup);
        } else {
          idsArray.push(newAdGroup._id);
          await this.rootStore.requestStore.newAdGroup(newAdGroup);
        }
      }
      //Put per a l'adGroup Original
      console.log(this.original);
      await this.rootStore.requestStore.modifyAdGroup(this.original);
      //Put per a tots els ads
      console.log(arrayAds, idsArray);
      await this.rootStore.requestStore.modifyAdListIndividual(
        arrayAds,
        idsArray
      );

      if (workingUser) {
        for (let id of idsArray) {
          this.rootStore.requestStore.changeWorkingNewForGroupEditor(
            id,
            this.rootStore.userStore.getUser().username,
            "GroupEditor",
            this.rootStore.userStore.getUser().username,
            [this.rootStore.userStore.getUser().username]
          );
        }
      } else {
        for (let id of idsArray) {
          this.rootStore.requestStore.changeWorkingNewForGroupEditor(
            id,
            null,
            "GroupEditor",
            this.rootStore.userStore.getUser().username,[]
          );
        }
      }
      //Get all ads again
      this.getAds();
      return idsArray;
    }
    return idsArray;
  };
}

export default GroupEditorStore;
